import { UseFormReturn } from 'react-hook-form';
import { IFieldElem } from '@models/IFormData';
import { v4 as uuidv4 } from 'uuid';
import { EnvironmentService } from '../services/EnvironmentService';
import { DictionariesService } from '../services/DictionariesService';
import { isIsoDateString } from './documentUtils';
import { all, create, evaluate } from 'mathjs';
import Moment from 'moment';
import { stringToDateFormat } from './helpersDatePicker';
import { DocumentLinksService } from '@services/DocumentLinksService';
import { DocumentAttachService } from '@services/DocumentAttachService';
import { AxiosResponse } from 'axios';

export enum FieldType {
    Field,
    SumColumn,
    DictExist,
    Count,
    CheckRow,
    setGuid,
    ParentField,
    GetDay,
    GetYear,
    GetMonth,
    OffsetDay,
    OffsetYear,
    OffsetMonth,
    OffsetWorkDay,
    Now,
    CurrentYear,
    NowOnlyDate,
    SplitAndGetByIndex,
    SplitAndGetCount,
    GetTableCell,
    GetTableCells,
    GetTableMaxCell,
    GetTableMinCell,
    GetDataFromSP,
    SetDate,
    SetTime,
    FormatStr,
    Length,
    StrToDate,
    GetLinksCount,
    GetAttachmentsCount,
    LastWorkDayAfterOffset,
}

export interface FormulaField {
    regex: RegExp;
    type: FieldType;
}

export interface IFormulaElem {
    index?: number;
    name: string;
    defValue: string | boolean | number | null | undefined | Date | any[];
    formulaPart: {
        [key: string]: string;
    };
    params: {
        [key: string]: string;
    };
    match: RegExpExecArray;
    type: FieldType;
}

export const _formulaElems: FormulaField[] = [
    {
        regex: /CHECKROW\((?<watch>.*?);;(?<filter>.*?);;(?<param>.?any|all)\)/g,
        type: FieldType.CheckRow,
    },
    {
        regex: /SUMCOLUMN\((?<watch>.*?);;(?<param>.*?)(;;(?<filter>.*?))?\)/g,
        type: FieldType.SumColumn,
    },
    {
        regex: /DICTEXIST\((?<name>.*?);;(?<column>.*?);;(?<watch>.*?);;(?<return>.*?)(;;(?<type>.*?))?\)/g,
        type: FieldType.DictExist,
    },
    {
        regex: /GETDATAFROMSP\((?<name>.*?);;(?<watch>.*?)((^$)|(;;(?<param>.*?)))?((^$)|(;;(?<param1>.*?)))?((^$)|(;;(?<param2>.*?)))?((^$)|(;;(?<param3>.*?)))?((^$)|(;;(?<param4>.*?)))?\)/g,
        type: FieldType.GetDataFromSP,
    },
    {
        regex: /SPLITANDGETBYINDEX\((?<watch>.*?);;(?<separator>.*?);;(?<index>.*?)(;;(?<skipEmpty>.*?))?\)/g,
        type: FieldType.SplitAndGetByIndex,
    },
    {
        regex: /SPLITANDGETCOUNT\((?<watch>.*?);;(?<separator>.*?)(;;(?<skipEmpty>.*?))?\)/g,
        type: FieldType.SplitAndGetCount,
    },
    {
        regex: /GETTABLECELL\((?<watch>.*?);;(?<columnName>.*?);;(?<rowIndex>.*?)\)/g,
        type: FieldType.GetTableCell,
    },
    {
        regex: /GETTABLECELLS\((?<watch>.*?);;(?<columnName>.*?);;(?<separator>.*?)\)/g,
        type: FieldType.GetTableCells,
    },
    {
        regex: /GETTABLEMAXCELL\((?<watch>.*?);;(?<columnName>.*?)\)/g,
        type: FieldType.GetTableMaxCell,
    },
    {
        regex: /GETTABLEMINCELL\((?<watch>.*?);;(?<columnName>.*?)\)/g,
        type: FieldType.GetTableMinCell,
    },
    {
        regex: /GETLINKSCOUNT\((?<tableKey>.*?)\)/g,
        type: FieldType.GetLinksCount,
    },
    {
        regex: /GETATTACHMENTSCOUNT\((?<tableKey>.*?)\)/g,
        type: FieldType.GetAttachmentsCount,
    },
    {
        regex: /COUNT\((?<watch>.*?)\)/g,
        type: FieldType.Count,
    },
    {
        regex: /LENGTH\((?<watch>.*?)\)/g,
        type: FieldType.Length,
    },
    {
        regex: /GETDAY\((?<watch>.*?)\)/g,
        type: FieldType.GetDay,
    },
    {
        regex: /GETYEAR\((?<watch>.*?)\)/g,
        type: FieldType.GetYear,
    },
    {
        regex: /GETMONTH\((?<watch>.*?)\)/g,
        type: FieldType.GetMonth,
    },
    {
        regex: /OFFSETDAY\((?<watch>.*?);;(?<param>.*?)?((^$)|(;;(?<param1>.*?)))?\)/g,
        type: FieldType.OffsetDay,
    },
    {
        regex: /OFFSETWORKDAY\((?<watch>.*?);;(?<param>.*?)?((^$)|(;;(?<param1>.*?)))?\)/g,
        type: FieldType.OffsetWorkDay,
    },
    {
        regex: /LASTWORKDAYAFTEROFFSET\((?<watch>.*?);;(?<offset>.*?);;(?<allowedWeekDays>.*?)\)/g,
        type: FieldType.LastWorkDayAfterOffset,
    },
    {
        regex: /OFFSETYEAR\((?<watch>.*?);;(?<param>.*?)\)/g,
        type: FieldType.OffsetYear,
    },
    {
        regex: /OFFSETMONTH\((?<watch>.*?);;(?<param>.*?)\)/g,
        type: FieldType.OffsetMonth,
    },
    {
        regex: /FORMATSTR\((?<watch>.*?);;(?<param>.*?)\)/g,
        type: FieldType.FormatStr,
    },
    {
        regex: /SETDATE\((?<watch>.*?);;(?<month>.*?);;(?<day>.*?);;(?<hour>.*?);;(?<minutes>.*?)\)/g,
        type: FieldType.SetDate,
    },
    {
        regex: /SETTIME\((?<watch>.*?);;(?<hour>.*?);;(?<minutes>.*?)\)/g,
        type: FieldType.SetTime,
    },
    {
        regex: /\{setGuid\}/g,
        type: FieldType.setGuid,
    },
    {
        regex: /\{now\}/g,
        type: FieldType.Now,
    },
    {
        regex: /\{currentYear\}/g,
        type: FieldType.CurrentYear,
    },
    {
        regex: /STRTODATE\((?<strdate>.*?)\)/g,
        type: FieldType.StrToDate,
    },
    {
        regex: /\{nowOnlyDate\}/g,
        type: FieldType.NowOnlyDate,
    },
    {
        regex: /{(?<watch>\|Document.*?)\}/g,
        type: FieldType.Field,
    },
    {
        regex: /{(\|Parent(?<watch>.*?))\}/g,
        type: FieldType.ParentField,
    },
    {
        regex: /{(?!(?:isView|isEdit|isNew|')\b)(?<watch>.*?)}/g,
        type: FieldType.Field,
    },
];

export class FormulaManager {
    private _rules: string;
    private _isDebug: boolean;
    private _formula: string | undefined;
    private _fields: IFormulaElem[] | undefined;
    private _formMethods: UseFormReturn<any> | undefined;
    private _parentRow: any | undefined;
    private _data: Record<string, IFieldElem> | undefined;

    private _boolExp: string[] = ['>', '<', '&&', '||', '==', '===', '!=', '!==', 'true', 'false', 'test'];

    private static _linkCountCache: { [id: string]: Promise<AxiosResponse<number, any>> | undefined } = {};
    private static _cacheDurationMs: number = 10000;

    constructor(rules: string) {
        let formula = rules;
        this._isDebug = false;
        if (formula && formula.startsWith('[debug]')) {
            this._isDebug = true;
            formula = formula.replace('[debug]', '');
            console.log(formula);
        }
        this._rules = formula;
    }

    Init(data?: Record<string, IFieldElem>, formMethods?: UseFormReturn<any>, parentRow?: any) {
        this._formMethods = formMethods;
        this._parentRow = parentRow;
        this._data = data;
        let m: RegExpExecArray | null;
        let f: IFormulaElem[] = [];
        let sourceRule = this.prepareVariables(this._rules, data);
        let formula = sourceRule;

        let i = 0;

        _formulaElems.forEach((fElem) => {
            while ((m = fElem.regex.exec(sourceRule)) !== null) {
                if (m.index === fElem.regex.lastIndex) {
                    fElem.regex.lastIndex++;
                }

                let match = m[0];
                if (
                    f.findIndex((m) => {
                        return m.match[0] === match;
                    }) === -1 &&
                    formula.includes(match)
                ) {
                    let watch = m.groups?.watch;
                    if (fElem.type == FieldType.SumColumn && watch) {
                        let parts = watch.split('||');
                        if (parts.length == 2) {
                            watch = parts[0];
                        }
                    }
                    let fValue = data ? data[watch!] : undefined;

                    let elem = {
                        index: fValue?.index,
                        name: fValue ? fValue.name : watch!,
                        defValue: fValue?.value,
                        formulaPart: m.groups!,
                        match: m,
                        type: fElem.type,
                        params: {},
                    } as IFormulaElem;

                    if (fElem.type === FieldType.GetLinksCount || fElem.type === FieldType.GetAttachmentsCount) {
                        // Для функций без параметра watch добавляем фиктивный параметр, т.к. он обязательный
                        if (elem.match && elem.match.groups) {
                            elem.match.groups['watch'] = 'param';
                        }
                    }

                    let fieldVal = '{' + i + '}';
                    const source = new RegExp(match.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
                    if (
                        fElem.type == FieldType.OffsetDay ||
                        fElem.type == FieldType.OffsetMonth ||
                        fElem.type == FieldType.OffsetWorkDay ||
                        fElem.type == FieldType.LastWorkDayAfterOffset ||
                        fElem.type == FieldType.OffsetYear ||
                        fElem.type == FieldType.SetDate ||
                        fElem.type == FieldType.SetTime ||
                        fElem.type == FieldType.GetDataFromSP
                    ) {
                        if (m && m.groups) {
                            [
                                'param',
                                'param1',
                                'param2',
                                'param3',
                                'param4',
                                'month',
                                'day',
                                'hour',
                                'minutes',
                                'offset',
                                'allowedWeekDays',
                            ].forEach((param) => {
                                if (m && m.groups) {
                                    if (m.groups[param] && isNaN(+m.groups[param])) {
                                        let fParam = data ? data[m.groups[param]] : undefined;
                                        if (fParam) {
                                            elem.params[m.groups[param]] = fParam.name;
                                        }
                                    }
                                }
                            });
                        }
                    }
                    f.push(elem);
                    formula = formula?.replace(source, fieldVal!);
                    i++;
                }
            }
        });
        this._formula = formula;
        this._fields = f;
    }

    private prepareVariables(rules: string, data?: Record<string, IFieldElem>) {
        let formula = rules;
        let variablesRegex: RegExp = /variables\((?<path>.*?)\)/g;
        let m: RegExpExecArray | null;
        let i = 0;
        while ((m = variablesRegex.exec(rules)) !== null) {
            if (m.index === variablesRegex.lastIndex) {
                variablesRegex.lastIndex++;
            }
            let res = '';

            let path = m.groups?.path;
            if (path && data && data['variables']) {
                res = path.split('.').reduce(function (o, k) {
                    let f = k.replace(/\[/g, '').replace(/\]/g, '');
                    return o && o[f];
                }, data['variables'].value as any);
            }

            const source = new RegExp(m[0].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');

            formula = formula?.replace(source, res);
            i++;
        }
        return formula;
    }

    GetFields() {
        let result: string[] = [];
        if (this._fields) {
            this._fields.forEach((f) => {
                let watch = f.match.groups?.watch!;
                if (f.type == FieldType.SumColumn && watch) {
                    let parts = watch.split('||');
                    if (parts.length == 2) {
                        watch = parts[0];
                    }
                }
                if (f.type == FieldType.ParentField) {
                    watch = '|Parent' + watch;
                }

                if (watch) {
                    result.push(watch);
                }
            });
            return result;
        }
        return [];
    }

    GetWatchFields() {
        if (this._fields) {
            let f: string[] = [];
            this._fields.forEach((field) => {
                f.push(field.name);
            });

            return f;
        }
        return [];
    }

    async EvalFormulaValues(isEdit: boolean, isNew: boolean, values?: any[]) {
        if (this._formula) {
            let f: string | undefined = '';
            try {
                f = await this.ReplaceFormulaValues(isEdit, isNew, values);
                let result;
                if (
                    this._boolExp.some((x) => {
                        return f ? f.indexOf(x) > -1 : false;
                    })
                ) {
                    result = eval(f!);
                } else {
                    result = evaluate(f!);
                }
                if (this._isDebug) console.log(result);
                if (result === Infinity) {
                    return 0;
                }
                return result;
            } catch (error) {
                console.log(this._rules);
                console.log(f);
                console.log(error);
                return;
                throw new Error('EvalFormulaValues');
            }
        }
        return true;
    }

    GetDefaultValues(isEdit: boolean, isNew: boolean, withoutQuotes?: boolean) {
        return this.getDefaultValues(isEdit, isNew, withoutQuotes);
    }

    async ReplaceFormulaValues(isEdit: boolean, isNew: boolean, values?: any[], withoutQuotes?: boolean) {
        if (this._isDebug) console.log(this._rules);
        if (values === undefined || values?.length === 0) {
            values = this.getDefaultValues(isEdit, isNew, withoutQuotes);
        }

        if (this._formula) {
            let f = this._formula;
            f = this.substitutes(isEdit, isNew, f);
            if (values && values.length > 0) {
                if (withoutQuotes && values.every((v: any) => v === null || v === undefined)) {
                    return '';
                }
                for (let index = 0; index < values.length; index++) {
                    let fieldVal = await this.getFieldsValue(values, index, withoutQuotes);

                    const source = new RegExp(('{' + index + '}').replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
                    f = f?.replace(source, fieldVal!);
                }
                if (withoutQuotes) {
                    f = f?.replace(/\|/g, ',');
                }
                if (this._isDebug) {
                    console.log(f);
                }
            }
            return f;
        }
        return this._formula;
    }

    private wrapSpecialChars(value: string) {
        return value.replace(/\n/g, '\\n');
    }

    public formatFieldValueByType(field: any) {
        if (isIsoDateString(field)) {
            let val = new Date(field); // Moment(value).toDate();
            return val.getTime().toString();
        } else {
            switch (typeof field) {
                case 'string':
                    return "'" + this.wrapSpecialChars(field) + "'";
                case 'number':
                    return '' + field + '';
                case 'object':
                    if (field instanceof Date) {
                        return field.getTime().toString();
                    } else {
                        return '' + field + '';
                    }

                    break;
                default:
                    return '' + field + '';
            }
        }
    }
    private formatDisplayFieldValue(field: any) {
        if (field === undefined || field === null) {
            return '';
        } else {
            return field;
        }
    }

    private getDefaultValues(isEdit: boolean, isNew: boolean, withoutQuotes?: boolean) {
        let values: any[] = [];
        if (this._fields && this._formula) {
            if ((isEdit || isNew) && this._formMethods) {
                this._fields.forEach((f) => {
                    if (
                        f.type == FieldType.setGuid ||
                        f.type == FieldType.ParentField ||
                        f.type == FieldType.SetDate ||
                        f.type == FieldType.Now ||
                        f.type == FieldType.CurrentYear ||
                        f.type == FieldType.StrToDate ||
                        f.type == FieldType.NowOnlyDate
                    ) {
                        if (withoutQuotes) {
                            values.push('null');
                        } else {
                            values.push(null);
                        }
                    } else {
                        values.push(this._formMethods?.getValues(f.name));

                        //Если есть еще одинпараметру формулы
                        if (f.formulaPart.param) {
                            //this._formMethods?.getValues().fields.find(x=>x.name=='|Document|Организатор_закупки|Организатор_закупки')?.value
                            let flds = this._formMethods?.getValues()?.fields as any[];
                            if (flds) {
                                let paramVal = flds.find((k) => k.name == f.formulaPart.param)?.value;
                                values.push(paramVal);
                            }
                        }
                    }
                });
            } else {
                this._fields.forEach((f) => {
                    if (withoutQuotes) {
                        if (f?.defValue === null) {
                            values.push('');
                        } else {
                            values.push(f?.defValue as number);
                        }
                    } else {
                        values.push(f?.defValue as number);
                    }
                });
            }
        }
        return values;
    }

    private async getFieldsValue(values: any[], index: number, withoutQuotes?: boolean) {
        let result;
        if (values && this._formula && this._fields) {
            let formulaElm = this._fields[index];
            if (formulaElm) {
                switch (formulaElm.type) {
                    case FieldType.SumColumn:
                        result = await this.sumColumn(values, formulaElm, index);
                        break;
                    case FieldType.DictExist:
                        result = await this.dictExist(values, formulaElm, index);
                        break;
                    case FieldType.GetDataFromSP:
                        result = await this.getDataFromSP(values, formulaElm, index);
                        break;
                    case FieldType.Count:
                        result = this.count(values, formulaElm, index);
                        break;
                    case FieldType.Length:
                        result = this.length(values, formulaElm, index);
                        break;
                    case FieldType.GetYear:
                        result = this.getYear(values, formulaElm, index);
                        break;
                    case FieldType.SetDate:
                        result = this.setDate(values, formulaElm, index);
                        break;
                    case FieldType.SetTime:
                        result = this.setTime(values, formulaElm, index);
                        break;
                    case FieldType.GetMonth:
                        result = this.getMonth(values, formulaElm, index);
                        break;
                    case FieldType.GetDay:
                        result = this.getDay(values, formulaElm, index);
                        break;
                    case FieldType.OffsetYear:
                        result = this.offsetYear(values, formulaElm, index);
                        break;
                    case FieldType.FormatStr:
                        result = this.formatStr(values, formulaElm, index);
                        break;
                    case FieldType.OffsetMonth:
                        result = this.offsetMonth(values, formulaElm, index);
                        break;
                    case FieldType.OffsetDay:
                        result = this.offsetDay(values, formulaElm, index);
                        break;
                    case FieldType.OffsetWorkDay:
                        result = await this.offsetWorkDay(values, formulaElm, index);
                        break;
                    case FieldType.LastWorkDayAfterOffset:
                        result = await this.getLastWorkDayAfterOffset(values, formulaElm, index);
                        break;
                    case FieldType.CheckRow:
                        result = await this.checkRow(values, formulaElm, index);
                        break;
                    case FieldType.setGuid:
                        result = uuidv4();
                        break;
                    case FieldType.Now:
                        let defVal = new Date();
                        result = new Date(
                            defVal.getFullYear(),
                            defVal.getMonth(),
                            defVal.getDate(),
                            defVal.getHours(),
                            defVal.getMinutes(),
                            0,
                            0,
                        ).toISOString();
                        break;
                    case FieldType.CurrentYear:
                        result = new Date().getFullYear();
                        break;
                    case FieldType.StrToDate:
                        result = this.strToDate(values, formulaElm, index);
                        break;
                    case FieldType.NowOnlyDate:
                        {
                            let defVal = new Date();
                            result = new Date(
                                defVal.getFullYear(),
                                defVal.getMonth(),
                                defVal.getDate(),
                                0,
                                0,
                                0,
                                0,
                            ).toISOString();
                        }
                        break;
                    case FieldType.ParentField:
                        result = this._parentRow ? this._parentRow[formulaElm.name] : '';
                        break;
                    case FieldType.SplitAndGetByIndex:
                        result = await this.splitAndGetByIndex(values, formulaElm, index);
                        break;
                    case FieldType.SplitAndGetCount:
                        result = await this.splitAndGetCount(values, formulaElm, index);
                        break;
                    case FieldType.GetTableCell:
                        result = await this.getTableCell(values, formulaElm, index);
                        break;
                    case FieldType.GetTableCells:
                        result = await this.getTableCells(values, formulaElm, index);
                        break;
                    case FieldType.GetTableMaxCell:
                        result = await this.getTableMaxCell(values, formulaElm, index);
                        break;
                    case FieldType.GetTableMinCell:
                        result = await this.getTableMinCell(values, formulaElm, index);
                        break;
                    case FieldType.GetLinksCount:
                        result = await this.getLinksCount(values, formulaElm, index);
                        break;
                    case FieldType.GetAttachmentsCount:
                        result = await this.getAttachmentsCount(values, formulaElm, index);
                        break;
                    default:
                        result = values[index];
                        break;
                }
            }
        }
        return withoutQuotes == undefined || withoutQuotes == false
            ? this.formatFieldValueByType(result)
            : this.formatDisplayFieldValue(result);
    }
    private async checkRow(values: any[], formulaElm: IFormulaElem, index: number) {
        let rows = values[index] as any[];
        let result = false;
        if (rows) {
            let filter = formulaElm.match.groups?.filter;
            let foundedCount = 0;

            for (let index = 0; index < rows.length; index++) {
                const row = rows[index];
                let checkResult = true;
                if (filter) {
                    let filterMng = new FormulaManager(filter);
                    filterMng.Init();
                    let coll = filterMng.GetFields();
                    let valuesFilter: any[] = [];
                    coll.forEach((field) => {
                        let val: any = undefined;
                        if (field.includes('|Document') && this._data) {
                            let fieldData = this._data[field];
                            val = this._formMethods?.getValues(fieldData.name);
                        } else {
                            val = row[field];
                        }

                        valuesFilter.push(val);
                    });
                    checkResult = await filterMng.EvalFormulaValues(false, false, valuesFilter);
                }
                if (checkResult) foundedCount++;
            }

            let type = formulaElm.match.groups?.param.trim();
            if (type == 'all') {
                result = rows.length === foundedCount;
            } else {
                result = foundedCount > 0;
            }
        }
        return result;
    }
    private count(values: any[], formulaElm: IFormulaElem, index: number) {
        let rows = values[index] as any[];
        if (rows === undefined || rows === null) {
            return 0;
        }
        return rows.length;
    }

    private length(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as string;
        if (value === undefined || value === null) {
            return 0;
        }
        return value.length;
    }

    private getYear(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        return defVal.getFullYear();
    }
    private setDate(values: any[], formulaElm: IFormulaElem, index: number) {
        let _year = this.getOffsetVal(formulaElm.name, formulaElm);
        let _month = this.getOffsetVal(formulaElm.match.groups?.month, formulaElm);
        let _day = this.getOffsetVal(formulaElm.match.groups?.day, formulaElm);
        let _hour = this.getOffsetVal(formulaElm.match.groups?.hour, formulaElm);
        let _minutes = this.getOffsetVal(formulaElm.match.groups?.minutes, formulaElm);

        let date = new Date(_year, _month - 1, _day, _hour, _minutes, 0, 0);
        return date.toISOString();
    }
    private setTime(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        let _hour = this.getOffsetVal(formulaElm.match.groups?.hour, formulaElm);
        let _minutes = this.getOffsetVal(formulaElm.match.groups?.minutes, formulaElm);

        let result = new Date(defVal.getFullYear(), defVal.getMonth(), defVal.getDate(), _hour, _minutes, 0, 0);
        return result.toISOString();
    }
    private getDay(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        return defVal.getDate();
    }
    private getMonth(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        return defVal.getMonth() + 1;
    }
    private offsetMonth(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        let offset = this.getOffsetVal(formulaElm.match.groups?.param, formulaElm);
        let result = new Date(
            defVal.getFullYear(),
            defVal.getMonth() + offset,
            defVal.getDate(),
            defVal.getHours(),
            defVal.getMinutes(),
            defVal.getSeconds(),
            defVal.getMilliseconds(),
        );
        return result.toISOString();
    }
    private offsetDay(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        let offset = this.getOffsetVal(formulaElm.match.groups?.param, formulaElm);
        let offsetConst = this.getOffsetVal(formulaElm.match.groups?.param1, formulaElm);
        if (offsetConst) {
            offset = offset + offsetConst;
        }
        let result = new Date(
            defVal.getFullYear(),
            defVal.getMonth(),
            defVal.getDate() + offset,
            defVal.getHours(),
            defVal.getMinutes(),
            defVal.getSeconds(),
            defVal.getMilliseconds(),
        );
        return result.toISOString();
    }

    private strToDate(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = formulaElm.match.groups?.strdate;
        let result = stringToDateFormat(defVal!, 'dd.mm.yyyy');
        return result.toISOString();
    }

    private async offsetWorkDay(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        let offset = this.getOffsetVal(formulaElm.match.groups?.param, formulaElm);
        let offsetConst = this.getOffsetVal(formulaElm.match.groups?.param1, formulaElm);
        if (offsetConst) {
            offset = offset + offsetConst;
        }
        let r = await EnvironmentService.offsetWorkDay(defVal, offset);
        return new Date(r.data).toISOString();
    }

    private async getLastWorkDayAfterOffset(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        let offset = this.getOffsetVal(formulaElm.match.groups?.offset, formulaElm);

        let allowedWeekDays: number[];
        try {
            allowedWeekDays = formulaElm.match.groups?.allowedWeekDays
                ? JSON.parse(formulaElm.match.groups?.allowedWeekDays).filter((item: any) => typeof item === 'number')
                : [];
        } catch {
            allowedWeekDays = [];
        }

        let r = await EnvironmentService.lastWorkDayAfterOffset(defVal, offset, allowedWeekDays);
        return new Date(r.data).toISOString();
    }

    private async dictExist(values: any[], formulaElm: IFormulaElem, index: number) {
        let code = values[index];
        let dictName = formulaElm.match.groups?.name!;
        let dictColumn = formulaElm.match.groups?.column!;
        let returnColumn = formulaElm.match.groups?.return;
        let type = formulaElm.match.groups?.type ? formulaElm.match.groups?.type : 'eq';
        if (this._isDebug) {
            console.log(dictName + ';;' + dictColumn + ';;' + code + ';;' + returnColumn + ';;' + type);
        }
        let response = await DictionariesService.getItemByFilter(dictName, dictColumn, code, type);

        if (response && response.data) {
            if (returnColumn == 'code') {
                return response.data.code;
            } else {
                for (let index = 0; index < response.data.fields.length; index++) {
                    const col = response.data.fields[index];
                    if (col.name == returnColumn && col.value) {
                        return col.value.toString();
                    }
                }
            }
        }

        return '-1';
    }
    private async getDataFromSP(values: any[], formulaElm: IFormulaElem, index: number) {
        let spName = formulaElm.match.groups?.name!;
        let column = formulaElm.match.groups?.param!;
        let returnParam = undefined;
        let params = {} as Record<string, any>;
        let tmpPrarams: any[] = [];
        let indexParams = 0;

        for (let index = 0; index < values.length; index++) {
            const param = values[index];
            if (Array.isArray(param)) {
                let result = param.map((a) => a[column]).join(',');
                params['param' + index] = result;
                indexParams++;
            } else {
                let obj = {};
                params['param' + index] = param;
                indexParams++;
            }
        }

        ['param1', 'param2', 'param3', 'param4'].forEach((param) => {
            if (formulaElm.match && formulaElm.match.groups) {
                if (formulaElm.match.groups[param]) {
                    let fParam = formulaElm.match.groups[param];
                    if (fParam) {
                        if (formulaElm.params[fParam]) {
                            let valField = this._formMethods?.getValues(formulaElm.params[fParam]);
                            if (valField) {
                                tmpPrarams.push(valField);
                            }
                        } else {
                            tmpPrarams.push(fParam);
                        }
                    }
                }
            }
        });
        if (tmpPrarams.length > 0) {
            returnParam = tmpPrarams.pop();
        }
        for (let index = 0; index < tmpPrarams.length; index++) {
            const element = tmpPrarams[index];
            params['param' + indexParams] = element;
            indexParams++;
        }

        let response = await EnvironmentService.getExtendData(spName, JSON.stringify(params));

        if (response && response.data && response.data.length > 0) {
            let res = response.data[0];
            if (returnParam !== undefined && Object.keys(res).includes(returnParam)) {
                return res[returnParam];
            } else if (Object.keys(res).includes(spName)) {
                return res[spName];
            } else {
                return undefined;
            }
        }

        return '';
    }

    private async getLinksCount(values: any[], formulaElm: IFormulaElem, index: number) {
        let tableKey = formulaElm.match.groups?.tableKey!;
        let docId = this._data ? ((this._data['doc_Id']?.value as string) ?? null) : null;

        if (!docId) return 0;
        const linksSvc = new DocumentLinksService(docId);

        let cacheKey = `${docId}_${tableKey}`;
        let cntCached = FormulaManager._linkCountCache[cacheKey];
        if (cntCached) {
            let res = await cntCached;
            return res?.data ?? 0;
        } else {
            let fetchCountFunc = linksSvc.fetchCount(tableKey);
            FormulaManager._linkCountCache[cacheKey] = fetchCountFunc;
            setTimeout(() => {
                FormulaManager._linkCountCache[cacheKey] = undefined;
            }, FormulaManager._cacheDurationMs);
            let response = await fetchCountFunc;
            return response?.data ?? 0;
        }
    }

    private async getAttachmentsCount(values: any[], formulaElm: IFormulaElem, index: number) {
        let tableKey = formulaElm.match.groups?.tableKey!;
        let docId = this._data ? ((this._data['doc_Id']?.value as string) ?? null) : null;

        if (!docId) return 0;

        const attachService = new DocumentAttachService(docId);

        let response = await attachService.fetchCount(tableKey);

        return response?.data ?? 0;
    }

    private offsetYear(values: any[], formulaElm: IFormulaElem, index: number) {
        let defVal = new Date(values[index]);
        let offset = this.getOffsetVal(formulaElm.match.groups?.param, formulaElm);
        let result = new Date(
            defVal.getFullYear() + offset,
            defVal.getMonth(),
            defVal.getDate(),
            defVal.getHours(),
            defVal.getMinutes(),
            defVal.getSeconds(),
            defVal.getMilliseconds(),
        );
        return result.toISOString();
    }

    private formatStr(values: any[], formulaElm: IFormulaElem, index: number) {
        let fieldVal = values[index];
        let type = formulaElm.match.groups?.param!;
        let result = '';
        if (fieldVal !== undefined && fieldVal !== null) {
            switch (type) {
                case 'money':
                    result = (+fieldVal)?.toLocaleString('ru-Ru', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });

                    break;
                case 'date':
                    result = Moment(fieldVal).format('DD.MM.YYYY');
                    break;
                case 'datetime':
                    result = Moment(fieldVal).format('DD.MM.YYYY HH:mm');
                    break;

                default:
                    result = fieldVal.toString();
                    break;
            }
        }
        return result;
    }

    private getOffsetVal(input: any, formulaElm: IFormulaElem) {
        if (input) {
            if (!isNaN(+input)) {
                return +input;
            } else {
                let key = input.indexOf('fields.[') > -1 ? input : formulaElm.params[input];
                let valField = this._formMethods?.getValues(key);
                if (valField) {
                    return +valField;
                }
            }
        }
        return 0;
    }

    private async sumColumn(values: any[], formulaElm: IFormulaElem, index: number, checkSubtable: boolean = true) {
        let rows = values[index] as any[];
        let sumResult: number = 0;
        let subTable: string | undefined = undefined;

        let math = create(all, {
            number: 'BigNumber', // Default type of number:
            // 'number' (default), 'BigNumber', or 'Fraction'
            precision: 64, // Number of significant digits for BigNumbers
            epsilon: 1e-60,
        });

        if (checkSubtable) {
            let parts = formulaElm.match.groups?.watch.split('||');
            if (parts && parts.length == 2) {
                subTable = '|' + parts[1];
            }
        }
        if (rows != undefined && rows != null && rows.length > 0) {
            for (let index = 0; index < rows.length; index++) {
                const row = rows[index];

                let filter = formulaElm.match.groups?.filter;
                let checkResult = true;
                if (filter && checkSubtable) {
                    let filterMng = new FormulaManager(filter);
                    filterMng.Init();
                    let coll = filterMng.GetFields();
                    let values: any[] = [];
                    coll.forEach((field) => {
                        let val: any = undefined;
                        val = row[field];
                        values.push(val);
                    });
                    checkResult = await filterMng.EvalFormulaValues(false, false, values);
                }
                if (checkResult) {
                    type ObjectKey = keyof typeof row;
                    let val = 0;
                    if (subTable === undefined) {
                        let attrNAme = formulaElm.match.groups?.param as ObjectKey;
                        val = row[attrNAme];
                    } else {
                        let attrNAme = subTable! as ObjectKey;
                        let valuesSubtable = [row[attrNAme]];
                        val = +(await this.sumColumn(valuesSubtable, formulaElm, 0, false));
                    }
                    if (val !== undefined && val !== null) {
                        let n: number = +val;
                        sumResult = (math as any).number(
                            (math as any).sum((math as any).bignumber(sumResult), (math as any).bignumber(n)),
                        );
                    }
                }
            }
        }

        return sumResult;
    }

    private substitutes(isEdit: boolean, isNew: boolean, val: string) {
        val = val?.toString().replace(/\{isEdit\}/g, isEdit ? 'true' : 'false');
        val = val?.toString().replace(/\{isNew\}/g, isNew ? 'true' : 'false');
        val = val?.toString().replace(/\{isView\}/g, !isEdit && !isNew ? 'true' : 'false');

        return val;
    }

    private async splitAndGetByIndex(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as string;
        let prm_separator = formulaElm.match.groups?.separator as string;
        let prm_index = parseInt(formulaElm.match.groups?.index!);
        let prm_skipEmpty = formulaElm.match.groups?.skipEmpty === 'true';
        let data = value.split(prm_separator);
        if (prm_skipEmpty) {
            data = data.filter((x) => x);
        }
        if (data.length == 0) return undefined;
        return data[prm_index];
    }

    private async splitAndGetCount(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as string;
        let prm_separator = formulaElm.match.groups?.separator as string;
        let prm_skipEmpty = formulaElm.match.groups?.skipEmpty === 'true';
        let data = value.split(prm_separator);
        if (prm_skipEmpty) {
            data = data.filter((x) => x);
        }
        return data.length;
    }

    private async getTableCell(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as any[];
        if (value == null) return null;
        let columnName = formulaElm.match.groups?.columnName as string;
        let rowIndex = parseInt(formulaElm.match.groups?.rowIndex as string);
        let data = value[rowIndex][columnName];
        return data;
    }

    private async getTableCells(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as any[];
        if (value == null) return null;
        let columnName = formulaElm.match.groups?.columnName as string;
        let data = value.map((row) => row[columnName]);

        let sep = formulaElm.match.groups?.separator as string;
        sep = sep ?? '|';
        return data.join(sep);
    }

    private async getTableMaxCell(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as any[];
        if (value == null) return null;
        let columnName = formulaElm.match.groups?.columnName as string;
        let data = value.map((row) => row[columnName]);

        if (data.length > 0) {
            switch (typeof data[0]) {
                case 'number':
                    return Math.max.apply(null, data);
                case 'object':
                    if (data[0] instanceof Date) {
                        return new Date(Math.max.apply(null, data));
                    } else {
                        return Math.max.apply(null, data);
                    }

                    break;
                default:
                    return data[0];
            }
        }

        return null;
    }

    private async getTableMinCell(values: any[], formulaElm: IFormulaElem, index: number) {
        let value = values[index] as any[];
        if (value == null) return null;
        let columnName = formulaElm.match.groups?.columnName as string;
        let data = value.map((row) => row[columnName]);

        if (data.length > 0) {
            switch (typeof data[0]) {
                case 'number':
                    return Math.min.apply(null, data);
                case 'object':
                    if (data[0] instanceof Date) {
                        return new Date(Math.min.apply(null, data));
                    } else {
                        return Math.min.apply(null, data);
                    }

                    break;
                default:
                    return data[0];
            }
        }

        return null;
    }

    public GetRules() {
        return this._rules;
    }

    public EvalFormula(formula: string) {
        if (formula) {
            try {
                let result;
                if (
                    this._boolExp.some((x) => {
                        return formula ? formula.indexOf(x) > -1 : false;
                    })
                ) {
                    result = eval(formula!);
                } else {
                    result = evaluate(formula!);
                }
                if (this._isDebug) console.log(result);
                if (result === Infinity) {
                    return 0;
                }
                return result;
            } catch (error) {
                console.log(this._rules);
                return;
                throw new Error('EvalFormula');
            }
        }
        return true;
    }
}
