import { UseFormReturn, useWatch } from 'react-hook-form';
import { IDocumentEditInModal } from '@models/Forms/IForms';
import { handlerFieldWatch } from '@utils/documentUtils';
import './EditInModal.scss';
import { IField, IFieldElem } from '@models/IFormData';
import { FormulaManager } from '@utils/FormulaManager';
import React, { useEffect, useState } from 'react';
import SubForm from './SubForm';
import { IDocumentData } from '@/models/document/IDocumentData';
import { MdEdit } from 'react-icons/md';
import DOMPurify from 'dompurify';

export interface IEditInModalProps<TFieldValues extends object = object> {
    editInModal?: IDocumentEditInModal;
    formMethods: UseFormReturn<any>;
    setError: (errors?: string[]) => void;
    isEdit: boolean;
    isNew: boolean;
    fields: Record<string, IFieldElem>;
    includedFields: number[];
    docId?: string;
}

const EditInModal = <TFieldValues extends object = object>({
    editInModal,
    formMethods,
    setError,
    isEdit,
    isNew,
    fields,
    includedFields,
    docId,
    ...props
}: IEditInModalProps<TFieldValues>) => {
    let visibilityRules = editInModal?.visibilityRules;
    const visibilityMng = new FormulaManager(visibilityRules!);
    visibilityMng.Init(fields, formMethods);
    const [showModal, setShowModal] = useState<boolean>(false);
    const activated = React.useRef(false);
    const [visibility, setVisibility] = useState<boolean>(false);

    const watchDataDisplayFormat = React.useRef<any[]>([]);
    const displayFormatMng = new FormulaManager(editInModal?.text!);
    displayFormatMng.Init(fields, formMethods);
    const [displayVal, setDisplayVal] = useState<string>('');

    const InitFormulas = async () => {
        let result = await visibilityMng.EvalFormulaValues(isEdit, isNew);
        if (activated.current) {
            setVisibility(result);
        }
        let values = displayFormatMng.GetDefaultValues(isEdit, isNew, true);
        let coll: any[] = [];
        values.forEach((x) => {
            if (x === null) {
                coll.push('');
            } else {
                coll.push(x);
            }
        });
        if (activated.current) {
            if (coll.length === 0 && editInModal?.text) {
                setDisplayVal(editInModal?.text);
            } else {
                let dataDisplay = await displayFormatMng.ReplaceFormulaValues(isEdit, isNew, coll, false);
                if (dataDisplay) {
                    dataDisplay = dataDisplay?.replace(/\'/g, '');
                    dataDisplay = dataDisplay?.replace(/\\n/g, '\n');
                    setDisplayVal(dataDisplay);
                }
            }
        }
    };

    useEffect(() => {
        activated.current = true;
        InitFormulas();
        return () => {
            activated.current = false;
        };
    }, []);

    const handlerDisplayVal = async (watch: any[]) => {
        let coll: any[] = [];
        watch.forEach((x) => {
            if (x === null) {
                coll.push('');
            } else {
                coll.push(x);
            }
        });

        if (activated.current) {
            if (coll.length === 0 && editInModal?.text) {
                setDisplayVal(editInModal?.text);
            } else {
                let dataDisplay = await displayFormatMng.ReplaceFormulaValues(isEdit, isNew, coll, false);
                if (dataDisplay) {
                    dataDisplay = dataDisplay?.replace(/\'/g, '');
                    dataDisplay = dataDisplay?.replace(/\\n/g, '\n');
                    setDisplayVal(dataDisplay);
                }
            }
        }
    };

    const watchDisplayFormat = useWatch({
        name: displayFormatMng.GetWatchFields(),
    });

    useEffect(() => {
        watchDataDisplayFormat.current = watchDisplayFormat;
        handlerDisplayVal(watchDisplayFormat);
    }, [watchDisplayFormat]);

    const watchVisibility = useWatch({
        name: visibilityMng.GetWatchFields(),
    });

    const onClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setShowModal(!showModal);
    };

    const onSubmitHandler = async (data: IField[]) => {
        data.forEach((field) => {
            const fieldProp = fields[field.name];

            formMethods.setValue(fieldProp.name as any, field.value as any);
        });

        if (activated.current) {
            setShowModal(!showModal);
        }
    };

    useEffect(() => {
        handlerFieldWatch(watchVisibility, isEdit, isNew, visibilityMng, setVisibility, activated);
    }, [watchVisibility, isEdit, isNew]);

    return editInModal && (isEdit || isNew) && visibility ? (
        <div className="editInModal-box" data-testid={editInModal.id ? `edit-in-modal-${editInModal.id}` : undefined}>
            {editInModal.textPosition === 'left' && (
                <div
                    className="content-text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayVal, { ADD_ATTR: ['target'] }) }}
                />
            )}
            <div
                className="editInModal-btn"
                onClick={onClick}
                style={editInModal.color ? { color: editInModal.color } : {}}
            >
                <MdEdit size="18px" />
            </div>
            {editInModal.textPosition === 'right' && (
                <div
                    className="content-text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayVal, { ADD_ATTR: ['target'] }) }}
                />
            )}
            {showModal && (
                <SubForm
                    form={editInModal.rows}
                    docId={docId}
                    isEdit={true}
                    formFields={formMethods.getValues() as IDocumentData}
                    onClose={() => {
                        setShowModal(!showModal);
                    }}
                    onSubmit={onSubmitHandler}
                    submitButtonCaption={editInModal.submitButtonCaption}
                    modalCaption={editInModal.modalCaption}
                ></SubForm>
            )}
        </div>
    ) : (
        <></>
    );
};

export default EditInModal;
