import React, { ReactNode } from 'react';
import { IControlProps, IPartialFormGroup } from '@controls/types';
import { IOption } from '@/types';
import FormControl from '../FormControl';

import { useHookFormController } from '@controls/HookFormProvider/HookFormProvider';
import Select, { ISelectProps } from '@atoms/Select/Select';

export type IYesNoControlProps = Omit<ISelectProps, 'onChange' | 'values'> &
    IControlProps & {
        formGroupProps?: IPartialFormGroup;
        label?: ReactNode;
        tooltip?: ReactNode;
        required?: boolean;
        trueText?: string;
        falseText?: string;
        onChangeVal?: (value: number) => void;
    };

const YesNoControl = ({
    name,
    rules,
    control,
    formState,
    shouldUnregister,
    formGroupProps,
    label,
    tooltip,
    required,
    onChangeVal,
    trueText,
    falseText,
    ...props
}: IYesNoControlProps) => {
    const Controller = useHookFormController();
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            shouldUnregister={shouldUnregister}
            render={({ field: { onChange, ref, value, ...fieldProps } }) => (
                <FormControl
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    required={required}
                    formState={formState}
                    {...formGroupProps}
                >
                    <Select
                        {...fieldProps}
                        {...props}
                        options={[
                            {
                                value: '1',
                                label: trueText ?? 'Да',
                            },
                            {
                                value: '0',
                                label: falseText ?? 'Нет',
                            },
                        ]}
                        onlySelect={true}
                        readOnly={true}
                        values={
                            [
                                {
                                    value: value?.toString() ?? '',
                                    label:
                                        value?.toString() === '1' ? trueText ?? 'Да' :
                                        value?.toString() === '0' ? falseText ?? 'Нет' :
                                        ''
                                } as IOption,
                            ]
                        }
                        onChange={(option: IOption[]) => {
                            if (option.length > 0) {
                                let val = option[0].value == '1' ? 1 : 0;
                                if (value === undefined || value === null || value.toString() != val.toString()) {
                                    onChange(val);
                                    if (onChangeVal) {
                                        onChangeVal(val);
                                    }
                                }
                            }
                        }}
                    />
                </FormControl>
            )}
        />
    );
};

export default YesNoControl;
