import React, { FC, RefObject, useEffect, useMemo, useState } from 'react';
import 'devextreme/data/odata/store';
import './PredefinedFilters.scss';
import Button from '@atoms/Button';
import { IListElement } from '@/types';
import Menu from '@atoms/Menu';
import { MdOutlineExpandMore } from 'react-icons/md';
import { IPredefinedFilter } from '@models/response/IPredefinedFiltersItems';
import { DataGrid } from 'devextreme-react/data-grid';

export interface IPredefinedFiltersProps {
    /**
     * Функция получения списка фильтров
     * */
    loadFilters?: () => IPredefinedFilter[] | undefined;

    /**
     * Событие изменения выбранного фильтра
     * */
    onChangeSelected?: (filter: IPredefinedFilter | undefined) => void;

    /**
     * Ссылка на компонент родительского грида
     */
    gridRef?: RefObject<DataGrid>;
}

const PredefinedFilters: FC<IPredefinedFiltersProps> = ({
    loadFilters,
    onChangeSelected,
    gridRef,
}: IPredefinedFiltersProps) => {
    const [filters, setFilters] = useState<IPredefinedFilter[] | undefined>();
    const [selectedFilter, setSelectedFilter] = useState<IPredefinedFilter | undefined>();

    useEffect(() => {
        const _filters = loadFilters?.();
        setFilters(_filters);
        setSelectedFilter(_filters ? _filters[0] : undefined);
    }, [loadFilters]);

    const filtersList = useMemo(() => {
        return (
            filters?.map((filter, index) => {
                return {
                    label: filter.name,
                    value: filter.key,
                    handler: () => {
                        onChangeSelected?.(filter);
                        setSelectedFilter(filter);
                    },
                } as IListElement;
            }) ?? []
        );
    }, [filters, onChangeSelected]);

    useEffect(() => {
        // записываем ключ фильтра в атрибут data-filter родительского контейнера (для кастомизации грида стилями)
        const gridWrapper = gridRef?.current?.instance.element().firstElementChild;
        if (selectedFilter) {
            gridWrapper?.setAttribute('data-filter', selectedFilter.key);
        } else {
            gridWrapper?.removeAttribute('data-filter');
        }
    }, [selectedFilter, gridRef]);

    return filtersList.length > 0 ? (
        <Menu key="predefinedFiltersMenu" list={filtersList} position="bottom-start">
            <div className="actions-menu">
                <Button
                    buttonType="secondary"
                    textColor="neutral"
                    size="s"
                    startAdornment={<MdOutlineExpandMore size="20" />}
                >
                    {selectedFilter?.name}
                </Button>
            </div>
        </Menu>
    ) : (
        <></>
    );
};

export default PredefinedFilters;
