import React, { ReactNode, RefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Manager, Reference } from 'react-popper';

import './Menu.scss';
import { IListElement, IMenuContext } from '@/types';
import List from './List';
import { classnames } from '@utils/classnames';
import Dropdown from '../Dropdown';
import { IDropdownProps } from '../Dropdown/Dropdown';

export interface IListProps extends Pick<IDropdownProps, 'position' | 'style' | 'offset' | 'containerRef'> {
    /** Кнопка открытия меню */
    children: ReactNode;
    /** Элементы меню */
    list?: IListElement[];
    /** Компонент вместо списка */
    content?: ReactNode;
    /** Класс */
    className?: string;
    /** При клике на элемент (children) переключать открытие/скрытие меню
     * Если false то при клике на элемент меню только показывать
     * @default true
     */
    toggleTarget?: boolean;
    /** Не активно при клике
     * @default false
     */
    disabled?: boolean;
    /** Срабатывает при изменении
     */
    onToggleMenu?: (show: boolean) => void;
    /** Ссылка на меню */
    menuRef?: RefObject<IMenuRefActions>;
}

/** Контекст для передачи функций работы с меню. */
export const MenuContext = React.createContext<IMenuContext>({
    onClose: () => {},
    show: false,
});

export interface IMenuRefActions {
    open: () => void;
    close: () => void;
    toggle: () => void;
}

const Menu: React.FC<IListProps> = ({
    list,
    children,
    content,
    className = '',
    toggleTarget = true,
    disabled = false,
    onToggleMenu,
    menuRef,
    ...props
}: IListProps) => {
    const toggleRef = useRef<HTMLDivElement>(null);

    /** Флаг отображения выпадающего списка  */
    const [show, setShow] = useState<boolean>(false);

    /** Изменение состояния выпадающего списка */
    const onToggle = useCallback(() => {
        setShow((show: boolean) => !show);
    }, [setShow]);

    const onClose = useCallback(() => {
        setShow(false);
    }, [setShow]);

    const onShow = useCallback(() => {
        setShow(true);
    }, [setShow]);

    useEffect(() => {
        onToggleMenu && onToggleMenu(show);
    }, [show]);

    useImperativeHandle(menuRef, () => ({
        open: () => {
            onShow();
        },
        close: () => {
            onClose();
        },
        toggle: () => {
            onToggle();
        },
    }));

    /** Клик по кнопке */
    const onClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            if (disabled) {
                return;
            }

            if (toggleTarget) {
                onToggle();
            } else {
                setShow(true);
            }
        },
        [onToggle, disabled, toggleTarget],
    );

    return (
        <MenuContext.Provider
            value={{
                onClose,
                show,
            }}
        >
            <Manager>
                <div className={classnames('rf-menu', className)} data-testid="rf-menu" ref={toggleRef}>
                    <Reference>
                        {(referenceProps) => (
                            <div {...referenceProps} className="rf-menu__toggle" onClick={onClick}>
                                {children}
                            </div>
                        )}
                    </Reference>

                    <Dropdown {...props} strategy="absolute" show={show} toggleRef={toggleRef} onClose={onClose}>
                        {content ? content : list && list.length > 0 && <List list={list} className={className} />}
                    </Dropdown>
                </div>
            </Manager>
        </MenuContext.Provider>
    );
};

export default Menu;
