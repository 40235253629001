import { useCallback, useEffect, useRef } from 'react';

export const useHoverDelay = (onLeave: () => void, delay: number = 1000) => {
    const isHovered = useRef(false);
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleMouseEnter = useCallback(() => {
        isHovered.current = true;
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
            timeoutId.current = null;
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        isHovered.current = false;
        timeoutId.current = setTimeout(() => {
            if (!isHovered.current) {
                onLeave();
            }
        }, delay);
    }, [delay, onLeave]);

    useEffect(() => {
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, []);

    return { handleMouseEnter, handleMouseLeave };
};
