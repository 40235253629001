import './../actions.scss';
import React, { useState } from 'react';
import { ActivityBaseActionExecutor } from '@utils/actions/ActivityBaseActionExecutor';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { getActivityIdFromCompositeId } from '@utils/helpers';
import { ModalSize } from '@atoms/Modal/Modal';
import { ActivityExecutionService } from '@services/actions/ActivityExecutionService';

export class TakeToWorkExecutor extends ActivityBaseActionExecutor {
    private _modalProps?: ITakeToWorkProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            objId: getActivityIdFromCompositeId(objId),
            actionKey: action.key,
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'm',
            hideComment: action.options?.hideComment ?? false,
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <TakeToWorkModal {...this._modalProps} /> : <></>;
    };
}

interface ITakeToWorkProps {
    actionKey: string;
    objId: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    hideComment: boolean;
    completeHandler?: (isSucceed: boolean) => void;
}

const TakeToWorkModal: React.FC<ITakeToWorkProps> = (props: ITakeToWorkProps) => {
    const [comment, setComment] = useState<string>('');
    const [errorText, setErrorText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const onSubmit = () => {
        const service = new ActivityExecutionService();
        setLoading(true);

        service
            .takeToWork(props.actionKey, props.objId, comment)
            .then((x) => {
                if (x.status == 200 && props?.completeHandler) props.completeHandler(true);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>Взять в работу</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div className="actForm">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}
                    {props.hideComment ? (
                        <></>
                    ) : (
                        <Textarea
                            className="action-modal-dlg-input"
                            placeholder="Комментарий"
                            rows={6}
                            name="commentText"
                            onChange={(e: any) => {
                                setComment(e.target.value);
                            }}
                        />
                    )}
                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                size="s"
                                aria-label={props.okButtonText}
                            >
                                {props.okButtonText}
                            </Button>
                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
