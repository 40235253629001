import React, {
    createRef,
    MouseEvent,
    ReactElement,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';

import './Tabs.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMenuContext, ITab } from '@/types';
import { classnames } from '@utils/classnames';
import Menu, { MenuContext } from '../Menu/Menu';
import Button from '../Button';
import { MdMenu } from 'react-icons/md';
import DocumentTabLabel, {
    IDocumentTab,
} from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabLabel/DocumentTabLabel';
import DocumentTabContent from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabContent/DocumentTabContent';
import DocumentTabMenuContainer from '@organisms/document/DocumentTabBuilder/tabs/DocumentTabMenuContainer/DocumentTabMenuContainer';
import { ResizePayload } from 'react-resize-detector/build/types/types';

export interface ITabsProps {
    /** Список вкладок */
    list: ITab[];
    /**
     * @default true
     * Показывать линию под табами
     * */
    showLine?: boolean;
    /**
     * Показывать меню если табы не вмещаются в контейре
     * @default true
     */
    showMenu?: boolean;
    /**
     * Размер табов
     * @default 'md'
     */
    size?: 'md' | 'sm';
    /**
     * Ренедерить неактивные табы
     * @default true
     */
    renderInactiveTabs?: boolean;

    /** Если во вкладках есть url, то через children пробрасывается <Router/> */
    children?: ReactNode | ReactNode[];

    onTabIndexChanged?: (tabIndex: number) => void;
}

const Tabs: React.FC<ITabsProps> = ({
    list,
    showLine = true,
    showMenu = true,
    size = 'md',
    renderInactiveTabs = true,
    children,
    onTabIndexChanged,
}: ITabsProps) => {
    const router = useNavigate();
    const location = useLocation();
    /** Ссылки на вкладки */
    const refs = useRef<RefObject<HTMLDivElement>[]>([]);
    /** Ссылка на линию */
    const lineRef = useRef<HTMLDivElement>(null);
    /** Последний индекс таба, который показан */
    const [lastVisibleIndex, setLastVisibleIndex] = useState<number>(list.length - 1);

    /** Определяем, если вкладки являются ссылками для роутинга */
    const isRouting: boolean = useMemo(() => list.every((t: ITab) => t.url), [list]);

    /** Устанавливаем индекс последнего видимого таба */
    const onResize = useCallback(
        ({ width }: ResizePayload) => {
            handleLastVisibleIndex(width ?? 0);
        },
        [showMenu, list],
    );

    const { ref } = useResizeDetector<HTMLDivElement>({
        onResize,
        refreshMode: 'debounce',
        refreshRate: 300,
    });

    useEffect(() => {
        if (!showMenu) {
            setLastVisibleIndex(list.length - 1);
        }
    }, [showMenu]);

    useEffect(() => {
        setActive((i: number) => {
            const index = list.findIndex((t: ITab) => {
                if (isRouting && t.url && (location.hash !== '' || location.pathname.includes('#'))) {
                    if (t.url.startsWith('#')) {
                        return t.exact ? t.url === location.hash : location.hash.includes(t.url);
                    } else {
                        return t.exact ? t.url === location.pathname : location.pathname.includes(t.url);
                    }
                }

                return t.active;
            });

            return index >= 0 && !list[index].disabled ? index : i;
        });
    }, [list, isRouting, location]);

    // -------------------------------------------------------------------------------------------------------------------

    /** Определяем ширину таба */
    const getWidthTab = (element: HTMLDivElement | null) => element?.getBoundingClientRect().width || 0;

    /** Получаем индекс последнего видимого таба */
    const handleLastVisibleIndex = (width = 0) => {
        if (!showMenu) {
            return;
        }

        const MENU_WIDTH = 48;
        const hasHiddenIndex = lastVisibleIndex < list.length - 1;
        let visibleIndex = lastVisibleIndex;
        let visibleWidth = hasHiddenIndex ? MENU_WIDTH : 0;

        for (let index = 0; index < list.length; index++) {
            if (refs.current?.[index]?.current) {
                if (width > visibleWidth + getWidthTab(refs.current[index].current)) {
                    visibleWidth += getWidthTab(refs.current[index].current);
                } else {
                    break;
                }
            }

            visibleIndex = index;
        }

        /** Определяем показывать ли следующий скрытый таб */
        if (hasHiddenIndex) {
            const nextIndex = visibleIndex + 1;
            const isLastIndex = nextIndex === refs.current.length - 1;
            const nextWidth = getWidthTab(refs.current?.[nextIndex]?.current);

            if (visibleWidth + nextWidth - (isLastIndex ? MENU_WIDTH : 0) <= width) {
                visibleWidth += isLastIndex ? nextWidth : nextWidth + MENU_WIDTH;
                visibleIndex = nextIndex;
            }
        }

        setLastVisibleIndex(visibleIndex);
    };

    // -------------------------------------------------------------------------------------------------------------------

    /** Определяем активную вкладку */
    const [active, setActive] = useState<number>(0);

    const [loadedTabs, setLoadedTabs] = useState<number[]>([]);

    /** Определяем является ли активный таб в меню */
    const isActiveIndexInMenu: boolean = active > lastVisibleIndex;

    /** Управление полоской */
    const setLinePosition = (element: HTMLDivElement) => {
        if (lineRef.current) {
            const width = element.offsetWidth;
            const x = element.offsetLeft;

            if (isActiveIndexInMenu) {
                lineRef.current.style.width = '0px';
            } else {
                lineRef.current.style.left = `${x}px`;
                lineRef.current.style.width = `${width}px`;
            }
        }
    };

    /** Устанавливаем активную вкладку */
    const onClick = (e: MouseEvent, i: number, element: HTMLDivElement | null) => {
        element && setLinePosition(element);

        if (isRouting && list[i].url && active !== i) {
            let locState = location.state as any;
            router(list[i].url as string, {
                state: {
                    fromPathname: locState?.fromPathname ?? null,
                    fromSearch: locState?.fromSearch ?? null,
                    fromHash: locState?.fromHash ?? null,
                },
            });
        }

        setActive(i);
        if (loadedTabs.indexOf(i) == -1) {
            let arr = loadedTabs;
            arr.push(i);
            setLoadedTabs(arr);
        }
        if (onTabIndexChanged) onTabIndexChanged(i);

        list[i].handler?.(refs.current[i].current as HTMLDivElement);
    };

    // -------------------------------------------------------------------------------------------------------------------

    /** Список вкладок */
    const nav = list.map((t: ITab, i: number) => {
        if (!refs.current[i]) {
            refs.current[i] = createRef();
        }

        const onMenuClickHandler = (e: MouseEvent) => onClick(e, i, refs.current[i].current);
        let docTab = t as IDocumentTab;

        return docTab.includedFields ? (
            <DocumentTabLabel
                key={i}
                tab={docTab}
                index={i}
                selected={active}
                lastVisibleIndex={lastVisibleIndex}
                size={size}
                refTab={refs.current[i]}
                mouseHandler={onMenuClickHandler}
            />
        ) : (
            <div
                key={i}
                className={classnames('rf-tabs__link', i > lastVisibleIndex && 'rf-tabs__link--hidden')}
                ref={refs.current[i]}
            >
                <button
                    type="button"
                    className={classnames(
                        'rf-tabs__button',
                        i === active && 'rf-tabs__button--active',
                        `rf-tabs__button--${size}`,
                    )}
                    disabled={t.disabled}
                    onClick={onMenuClickHandler}
                >
                    {!!t.icon && <div className="rf-tabs__icon">{t.icon}</div>}
                    {!!t.label && <div>{t.label}</div>}
                </button>
            </div>
        );
    });

    /** вкладки */
    const tabsContent = renderInactiveTabs ? (
        list.map((t: ITab, i: number) => {
            let docTab = t as IDocumentTab;
            return docTab.includedFields ? (
                <div key={i} className={classnames('rf-tabs__content_box', i != active && 'rf-tabs__content_hide')}>
                    <DocumentTabContent tab={docTab} />
                </div>
            ) : (
                <div key={i} className={classnames('rf-tabs__content_box', i != active && 'rf-tabs__content_hide')}>
                    {i == active || loadedTabs.indexOf(i) > -1 ? t.tab : <></>}
                </div>
            );
        })
    ) : (
        <div className={classnames('rf-tabs__content_box')}>{list[active]?.tab}</div>
    );

    /** Список вкладок в меню */
    const menuNav = (
        <MenuContext.Consumer>
            {({ onClose }: IMenuContext) =>
                list.reduce((acc: ReactElement[], curr: ITab, i: number) => {
                    let isDocTab = (curr as IDocumentTab).formMethods !== undefined;
                    if (i > lastVisibleIndex) {
                        const onMenuClickHandler = (e: MouseEvent) => {
                            onClick(e, i, refs.current[i].current);
                            onClose();
                        };

                        let menuContent = (
                            <button
                                type="button"
                                className={classnames(
                                    'rf-tabs__button',
                                    'rf-tabs__menu-button',
                                    i === active && 'rf-tabs__menu-button--active',
                                )}
                                disabled={curr.disabled}
                                onClick={onMenuClickHandler}
                            >
                                {!!curr.icon && (
                                    <div className={classnames('rf-tabs__icon', 'rf-tabs__menu-icon')}>{curr.icon}</div>
                                )}
                                {!!curr.label && <div>{curr.label}</div>}
                            </button>
                        );
                        return [
                            ...acc,
                            <div key={i} className={classnames('rf-tabs__link', 'rf-tabs__menu-link')}>
                                {isDocTab ? (
                                    <DocumentTabMenuContainer tab={curr as IDocumentTab} children={menuContent} />
                                ) : (
                                    menuContent
                                )}
                            </div>,
                        ];
                    }

                    return acc;
                }, [])
            }
        </MenuContext.Consumer>
    );

    /** Определяем есть ли табы в меню */
    const hasMenuNav = lastVisibleIndex < list.length - 1;

    /** Устанавливаем линию на активную вкладку при инициализации */
    useLayoutEffect(() => {
        if (nav.length > 0 && refs.current[active].current) {
            const element = refs.current[active].current;
            element && setLinePosition(element);
        }
    }, [nav]);

    // -------------------------------------------------------------------------------------------------------------------

    return (
        <div className={'rf-tabs rf-tabs--buttons'} ref={ref}>
            <nav className={classnames('rf-tabs__navigation', showLine && 'rf-tabs__navigation__line')}>
                <div
                    className={classnames(
                        'rf-tabs__navigation-list',
                        !showMenu && 'rf-tabs__navigation-list--without-menu',
                    )}
                >
                    {nav}
                    {hasMenuNav && (
                        <Menu
                            content={<div className="button-group__menu">{menuNav}</div>}
                            position="bottom-start"
                            className={classnames('rf-tabs__menu', isActiveIndexInMenu && 'rf-tabs__menu--active')}
                        >
                            <Button
                                buttonType="ghost"
                                size="xs"
                                data-testid="button-menu-group__more"
                                className="rf-tabs__menu__btn"
                                startAdornment={<MdMenu />}
                            />
                        </Menu>
                    )}
                </div>
                <div className="rf-tabs__navigation-line" ref={lineRef} />
            </nav>

            {(children || list.length > 0) && (
                <div className="rf-tabs__content">{children ? children : tabsContent}</div>
            )}
        </div>
    );
};

export default Tabs;
