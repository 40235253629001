import { IFormValue, IFormValues } from '@models/Forms/IForms';
import { DocumentService } from '../services/DocumentService';
import { convertListToMap } from './documentUtils';

export const getABookFormValues = async (docId: string, formValues?: IFormValues) => {
    const map = {} as Record<string, any>;
    const attrs = formValues?.formValue.map((item: IFormValue) => item.attr);
    if (attrs) {
        if (formValues && formValues != null) {
            await DocumentService.getFormData(docId, attrs).then((response) => {
                let fields = convertListToMap(response.data.fields);
                for (const ele of formValues?.formValue) {
                    let val = fields[ele.attr].value;

                    if (ele.column) {
                        if (ele.function === '{join}') {
                            map[ele.key] = (val as [])?.map((item: any) => `'${item[ele.column]}'`).join(',') ?? val;
                        } else {
                            map[ele.key] = (val as [])?.map((item: any) => item[ele.column]) ?? val;
                        }
                    } else {
                        map[ele.key] = val;
                    }
                }
            });
        }

        return JSON.stringify(map);
    }
};
