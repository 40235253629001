import React, { ReactNode, useMemo } from 'react';
import './PageWithSections.scss';
import { Link } from 'react-router-dom';
import Tile from '../../atoms/Tile';
import { IPageSection } from '@/types/projects.types';
import { IButtonGroup, ITab } from '@/types';
import useTableOfContents from '@hooks/useTableOfContents';
import Preloader from '@atoms/Preloader';
import Tabs from '@atoms/Tabs';
import ButtonGroup from '@atoms/ButtonGroup';
import { MdChevronLeft } from 'react-icons/md';

export interface IPageWithSectionsProps {
    title?: ReactNode;
    backUrl?: string;
    onBackUrlClick?: () => void;
    sections?: IPageSection[];
    asideHeader?: ReactNode;
    /** [DEPRECATED] Fixed action menu */
    actionMenu?: ReactNode;
    /** Всегда отображает панель с кнопками внизу страницы*/
    actionMenuAlwaysBottom?: boolean;
    /** Показать прелоадер
     * @default false */
    preloader?: boolean;
    /** Показать боковое меню заголовков секций
     * @default true */
    showNavigation?: boolean;
    /** Отключает подсветку активного элемента меню
     * @default false */
    showNavigationPosition?: boolean;
    /** Navigation tabs */
    navigation?: ITab[];
    /** Показать заголовок
     * @default true */
    showHeader?: boolean;
    /** Кнопки действий внизу страницы
     * @default [] */
    buttonsGroup?: IButtonGroup[];
    /** Количество кнопок для меню
     * @default 2 */
    countOfButtonsGroup?: number;
    /** Селектор родительского элемента со скролом */
    parentSelector: string;
}

// FIXME: Elements must have sufficient color contrast
const PageWithSections: React.FC<IPageWithSectionsProps> = ({
    title,
    backUrl,
    onBackUrlClick,
    sections,
    asideHeader,
    actionMenu,
    preloader = false,
    showNavigation = true,
    navigation,
    showHeader = true,
    actionMenuAlwaysBottom = false,
    showNavigationPosition = false,
    countOfButtonsGroup = 2,
    buttonsGroup = [],
    parentSelector,
}: IPageWithSectionsProps) => {
    /** Отображение секций */
    const sectionsJSX = sections?.map((section: IPageSection) => {
        return (
            <section key={section.id} id={section.id} className="rf-page__section-block">
                {section.withoutTileWrapper ? (
                    <> {section.component}</>
                ) : (
                    <Tile hideBackground={section.hideBackground}>
                        {section.title && <h2 className="rf-page__section-title">{section.title}</h2>}
                        {section.component}
                    </Tile>
                )}
            </section>
        );
    });

    // -------------------------------------------------------------------------------------------------------------------

    /** Активная секция при скролле */
    const { activeTitle, onClick } = useTableOfContents({
        parentSelector: parentSelector,
        selector: '.rf-page__section-block',
        deps: [preloader],
    });

    /** Боковая навигация для секций */
    const asideJSX = sections
        ?.filter((section: IPageSection) => !!section.title)
        .map((section: IPageSection, idx: number) => {
            const onNavClick = () => {
                const block = document.getElementById(section.id);

                block?.scrollIntoView({ behavior: 'smooth', block: 'start' });

                onClick({
                    activeIndex: idx,
                    activeTitleId: section.id,
                });
            };

            const activeClass =
                showNavigationPosition && activeTitle.activeTitleId === section.id ? 'rf-page__aside-link--active' : '';
            return (
                <div key={section.id} className={`rf-page__aside-link ${activeClass}`} onClick={onNavClick}>
                    {section.asideTitle ?? section.title}
                </div>
            );
        });

    // -------------------------------------------------------------------------------------------------------------------

    const showAside = !!sections && sections.some((s: IPageSection) => !!(s.asideTitle ?? s.title));
    const styleAsideBlock = useMemo(() => ({ marginTop: navigation?.length ? '70px' : 0 }), [navigation?.length]);

    const asideBlock = showNavigation && showAside && (
        <aside className="rf-page__content-aside" style={styleAsideBlock}>
            {asideHeader ? <div className="rf-page__aside-header">{asideHeader}</div> : null}
            <div className="rf-page__aside-inner">
                <nav className="rf-page__aside-nav" aria-label="Секции">
                    {asideJSX}
                </nav>
            </div>
        </aside>
    );

    // -------------------------------------------------------------------------------------------------------------------

    const onBackClick = (e: React.MouseEvent) => {
        if (onBackUrlClick) {
            e.preventDefault();
            onBackUrlClick();
        }
    };

    // -------------------------------------------------------------------------------------------------------------------

    return (
        <div className="rf-sections-page">
            <header className={`rf-page__sections-header ${showHeader ? '' : 'rf-page__sections-header--hidden'}`}>
                {backUrl && (
                    <Link
                        to={backUrl}
                        onClick={onBackClick}
                        className="rf-page__sections-header-back"
                        aria-label="Назад"
                    >
                        <MdChevronLeft size="24" />
                    </Link>
                )}
                <h2 className="rf-page__sections-title">{title}</h2>
            </header>

            <div className="rf-page__content--sections">
                {preloader ? (
                    <Preloader />
                ) : (
                    <>
                        <div className="rf-page__content-sections">
                            {!!navigation?.length && (
                                <div className="rf-page__tabs">
                                    <Tabs list={navigation} />
                                </div>
                            )}

                            {sectionsJSX}
                        </div>

                        <div className="rf-page__aside">{asideBlock}</div>
                    </>
                )}
            </div>

            {!preloader && actionMenu
                ? actionMenu
                : buttonsGroup !== undefined &&
                  buttonsGroup.length > 0 && (
                      <div className="rf-page__buttons-group">
                          <ButtonGroup max={countOfButtonsGroup} list={buttonsGroup} />
                      </div>
                  )}
        </div>
    );
};
export default PageWithSections;
