import React, { FC, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './TreeModeControl.scss';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IDictFilter } from '@models/Forms/IForms';
import DevExpressTreeList from '@atoms/DevExpress/TreeList/DevExpressTreeList';
import TreeList, {
    Column,
    ColumnFixing,
    FilterRow,
    Paging,
    RemoteOperations,
    Scrolling,
    Selection,
} from 'devextreme-react/tree-list';
import { simulateMouseClick } from '@utils/helpers';
import { IDictSettings } from '@atoms/Dictpicker/IDictSettings';

import DataSource from 'devextreme/data/data_source';
import {
    ContentReadyEvent,
    EditorPreparingEvent,
    InitializedEvent,
    Node,
    NodesInitializedEvent,
} from 'devextreme/ui/tree_list';
import {
    checkGridRowsByScript,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getLoadOptionsQuery,
    onCellHoverChanged,
} from '@utils/dataGridUtils';
import { IDictpickerRefActions } from '@atoms/Dictpicker/Dictpicker';
import { ValueType } from '@/types/ValueType';
import { sendErrorMsg } from '@/components/molecules/Errors';
import { ConfigService } from '@/configuration/services/configService';

export interface ITreeModeControlProp extends IDictSettings {
    onSelectedRowItems: (value: IDictionaryData[]) => void;
    onSelectedKeys: (value: string[]) => void;
    dictAttributes: string[];
    filterResponse: IDictFilter;
    maxStructLevel: number;
    controlRef: RefObject<IDictpickerRefActions>;
}

const TreeModeControl: FC<ITreeModeControlProp> = (p: ITreeModeControlProp) => {
    const config = ConfigService.get();
    const applyFilterOnClick = config.application.applyGridFilterOnClick ?? false;

    const tree = () => {
        return treeRef?.current?.instance;
    };

    useImperativeHandle(p.controlRef, () => ({
        reset: () => {
            treeRef?.current?.instance.refresh();
        },
        setSelected: (items: string[]) => {
            //setSelectedItems(items);
        },
    }));

    const filterPredicate = (currentItem: IDictionaryData, filter: any) => {
        const value = filter.columnName
            ? currentItem.fields.find((x) => x.name === filter.columnName)?.value
            : currentItem.code;

        if (filter.exlude && filter.exlude?.codes?.indexOf(value) !== -1) return false;

        if (filter.codes?.indexOf(value) !== -1) return filter.isShow;

        return !filter.isShow;
    };

    const filterResponseVoid = (originalItems: IDictionaryData[], filter: IDictFilter) => {
        if (filter.filter && filter.filter != '') {
            const fObj = JSON.parse(filter.filter.replace(/\'/g, '"'));
            const filteredObject = [] as IDictionaryData[];
            for (let j = 0; j < originalItems.length; j++) {
                const item = originalItems[j];
                const filteredChild = filterPredicate(item, fObj);
                if (filteredChild) filteredObject.push(item);
            }

            return filteredObject;
        } else {
            return originalItems;
        }
    };

    /**
     * TODO Удалить после правки бэкенда
     * TODO Костыль для раскрытия узлов при откртыии словаря с учётом предиката (fix перед релизом)
     */
    const [isFirstLoadReady, setIsFirstLoadReady] = useState<boolean>(false);

    useEffect(() => {
        /**
         * TODO Удалить после правки бэкенда
         */
        if (isFirstLoadReady) {
            setIsFirstLoadReady(false);
        }

        // const expandAndSelectItems = async (selected: IDictionaryData[]) => {
        //     selected.forEach((item) => {
        //         let ids = item.fullId.split('/');
        //         if (ids.length >= 2) {
        //             ids.pop();
        //             ids.forEach((id) => {
        //                 tree()
        //                     ?.expandRow(id)
        //                     .then((_) => {
        //                         tree()?.selectRows(
        //                             selected.map((item) => item.id),
        //                             true
        //                         );
        //                     });
        //             });
        //         }
        //     });
        // };

        // const getTreeItemsByCode = async () => {
        //     DictionariesService.getTreeItemsByCode(p.dictName, p.selected).then((response) => {
        //         let selected = response.data;
        //         expandAndSelectItems(selected);
        //     });
        // };

        // if (p.dictAttributes) {
        //     if (p.maxStructLevel !== undefined && p.selected && p.selected.length > 0) {
        //         getTreeItemsByCode().catch(console.error);
        //     }
        // }
    }, [p.dictName]);

    const getUrlParam = (loadOptions: any) => {
        let params = '?';
        params += getLoadOptionsQuery(loadOptions);
        return params;
    };

    const treeSource = useMemo(() => {
        return new DataSource<IDictionaryData, string>({
            /**
             * TODO Перенести фильтрацию из метода load в пост-обработку (корректно работают внутренние счётчики)
             * */
            //postProcess: treePostProcessFunction,

            /**
             * TODO Для TreeList перестаёт работать раскрытие узлов при применении фильтра, починить
             * */
            //filter: filterRemoteQuery.length > 0 ? filterRemoteQuery : null,

            requireTotalCount: false,
            key: 'id',
            async load(loadOptions: any) {
                let params = getUrlParam(loadOptions);
                params += '&formValues=' + (await p.getFormValuesAsync());
                if (p.predicatesCache) {
                    params += '&predicates=' + p.predicatesCache;
                }
                if (p.loadMode) {
                    params += '&loadMode=' + p.loadMode;
                }
                const parentIdsParam = loadOptions.parentIds ? loadOptions.parentIds : '';
                return DictionariesService.getTreeItems(p.dictName, parentIdsParam, params).then((response) => {
                    validateLoadedData(response.data.data);
                    if (p.filterResponse) {
                        let _data = filterResponseVoid(response.data.data, p.filterResponse);
                        response.data.totalCount = _data.length;
                        response.data.data = _data;
                    }

                    return response.data;
                });
            },
        });
    }, [p.dictName, p.filterResponse]);

    const validateLoadedData = (data: IDictionaryData[]) => {
        if (
            p.loadedDataValidators &&
            p.loadedDataValidators.validators &&
            p.loadedDataValidators.validators.length > 0
        ) {
            let messages: string[] = [];

            p.loadedDataValidators.validators.forEach((validator) => {
                if (!checkGridRowsByScript(data, validator.script)) {
                    messages.push(validator.message);
                }
            });

            if (messages.length > 0) {
                sendErrorMsg({
                    message: messages,
                });
            }
        }
    };

    /**
     * Фильтрация элементов и управление видимостью элементов
     * */
    const filterNodesByScript = (nodes: Node[], script: string) => {
        nodes?.forEach((child, index) => {
            child.visible = filterNodeByScript(child, script)!;
            setNodesVisibility(child.children, child.visible);
        });
    };

    /**
     *
     * */
    const filterNodeByScript = (node: Node<IDictionaryData, string>, script: string) => {
        /**
         * WARNING! Begin sections of functions for templates, do not rename
         * */
        let code = (n: Node<IDictionaryData, string> = node) => {
            return n.data?.code;
        };

        let field = (name: string, n: Node<IDictionaryData, string> = node) => {
            return getFieldValue(n, name);
        };

        let childrens = (n: Node<IDictionaryData, string> = node) => {
            return n.children;
        };

        let intersect = (array1: string[], array2: string[]) => {
            return array1 && array2 && array1.some((item) => array2.includes(item));
        };
        /**
         * WARNING! End sections of functions for templates
         * */

        return eval(script);
    };

    const getFieldValue = (node: Node<IDictionaryData, string>, name: string) => {
        return node.data?.fields.find((field) => field.name === name)?.value;
    };

    const checkIsRowSelectable = (data?: IDictionaryData) => {
        if (p.selectableLevels) {
            if (p.selectableLevels === 'last') {
                return !data?.hasChild;
            }
            if (p.selectableLevels === 'first') {
                return data?.parent === '';
            }
        }
        return true;
    };

    const onTreeInitialized = useCallback((e: InitializedEvent<IDictionaryData, string>) => {}, []);

    const setNodesVisibility = (nodes?: Node[], visibility?: boolean) => {
        nodes?.forEach((node) => {
            setNodesVisibility(node.children, visibility);
            node.visible = visibility ?? false;
        });
    };

    const onTreeNodesInitialized = useCallback(
        (e: NodesInitializedEvent<IDictionaryData, string>) => {
            if (p.filterResponse?.script) {
                filterNodesByScript(e.root.children!, p.filterResponse?.script);
            }

            if (p.visibleLevels && p.visibleLevels !== '') {
                if (p.visibleLevels === 'first') {
                    setNodesHasChildrenFlag(e.root, 1);
                } else {
                    let maxLevel = parseInt(p.visibleLevels);
                    if (!Number.isNaN(maxLevel) && maxLevel >= 1) {
                        setNodesHasChildrenFlag(e.root, maxLevel);
                    } else {
                        console.error(`Некорректно указана настройка 'visibleLevels' (${p.visibleLevels})`);
                    }
                }
            }
        },
        [p.filterResponse, p.visibleLevels],
    );

    /**
     * Рекурсивно проходит по дочерним элементам узла и отключает раскритие узла при превышении максимального уровня вложенности (который нумеруется с 1)
     * @param node
     * @param maxLevel
     */
    const setNodesHasChildrenFlag = (node: Node<IDictionaryData, string>, maxLevel: number) => {
        node.children?.forEach((child) => {
            if (child.level + 1 >= maxLevel) {
                child.hasChildren = false;
                // Снимаем флаг наличия дочерних элементов
                if (child.data) {
                    child.data.hasChild = false;
                }
            } else {
                setNodesHasChildrenFlag(child, maxLevel);
            }
        });
    };

    const onTreeContentReady = useCallback((e: ContentReadyEvent<IDictionaryData, string>) => {
        /**
         * TODO Удалить после правки бэкенда
         */
        setIsFirstLoadReady(true);
    }, []);

    /**
     * TODO Удалить после правки бэкенда
     */
    useEffect(() => {
        const expandAndSelectItems = async (selected: IDictionaryData[]) => {
            selected.forEach((item) => {
                let ids = item.fullId.split('/');
                if (ids.length >= 2) {
                    ids.pop();
                    ids.forEach((id) => {
                        tree()
                            ?.expandRow(id)
                            .then((_) => {
                                tree()
                                    ?.selectRows(
                                        selected.map((item) => item.id),
                                        true,
                                    )
                                    .then(() => {
                                        // перерисовываем грид, т.к. не проставляются чекбоксы
                                        tree()?.repaint();
                                    });
                            });
                    });
                } else {
                    tree()
                        ?.selectRows([item.fullId], true)
                        .then(() => {
                            // перерисовываем грид, т.к. не проставляются чекбоксы
                            tree()?.repaint();
                        });
                }
            });
        };

        const getTreeItemsByCode = async () => {
            DictionariesService.getTreeItemsByCode(p.dictName, p.selected).then((response) => {
                let selected = response.data;
                expandAndSelectItems(selected);
            });
        };

        if (isFirstLoadReady) {
            if (p.dictAttributes) {
                if (p.maxStructLevel !== undefined && p.selected && p.selected.length > 0) {
                    getTreeItemsByCode().catch(console.error);
                }
            }
        }
    }, [isFirstLoadReady]);

    const onTreeEditorPreparing = useCallback((e: EditorPreparingEvent<IDictionaryData, string>) => {
        if (applyFilterOnClick && e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function () {
                // применение фильтра по нажатию Enter
                simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
            };
        }
        if (e.parentType === 'dataRow') {
            e.editorOptions.disabled = !checkIsRowSelectable(e.row?.data);
        }
    }, []);

    const onSelectionChanged = useCallback((e: any) => {
        let property = 'id';
        if (!p.isMultiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                let item = e.selectedRowsData.pop();
                e.component.selectRows([item[property]], false);
                p.onSelectedKeys([item[property]]);
                p.onSelectedRowItems([item!]);
            } else if (e.selectedRowKeys.length == 0) {
                p.onSelectedKeys([]);
                p.onSelectedRowItems([]);
            }
        } else {
            p.onSelectedKeys(e.selectedRowKeys);
            p.onSelectedRowItems(e.selectedRowsData);
        }
    }, []);

    const treeRef = useRef<TreeList>(null);

    const getCodeColumn = useCallback(() => {
        let attr = p.gridAttribute?.attrs.find((a) => {
            return a.key === 'code';
        });
        if (attr) {
            return (
                <Column
                    key={'code'}
                    dataField={'code'}
                    caption={attr.name}
                    width={attr.width}
                    defaultSortOrder={attr.sortOrder}
                />
            );
        }
        if (p.gridAttribute == undefined) {
            return <Column key={'code'} dataField={'code'} caption="Код" width="auto" />;
        }
        return <></>;
    }, []);

    const getSchemeColumns = useCallback((dictAttributes: string[]) => {
        return dictAttributes.map((schemeColumn, _) => {
            const index = p.dictAttributes.indexOf(schemeColumn);
            const attr = p.gridAttribute?.attrs.find((a) => !a.joined && a.key === schemeColumn);
            if (attr !== undefined) {
                return (
                    <Column
                        key={`col_${index}`}
                        allowFiltering={true}
                        caption={attr?.name}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        width={attr?.width}
                        visible={true}
                        allowSorting={true}
                        filterOperations={getColumnFilterOperationsByColumnDataType(
                            getColumnDataTypeByFieldDataType((attr?.type ? attr?.type : 'string') as ValueType),
                        )}
                        encodeHtml={true}
                        cellRender={cellUrlRender}
                        defaultSortOrder={attr.sortOrder}
                    />
                );
            }
            if (p.gridAttribute === undefined) {
                return (
                    <Column
                        key={`col_${index}`}
                        allowFiltering={true}
                        caption={schemeColumn}
                        dataField={`fields[${index}].value`}
                        dataType={'string'}
                        visible={true}
                        allowSorting={true}
                        filterOperations={['contains']}
                        encodeHtml={true}
                    />
                );
            }
            return <React.Fragment key={index}></React.Fragment>;
        });
    }, []);

    const getColumns = useCallback(() => {
        const attrs = p.gridAttribute?.attrs.filter((a) => a.joined);
        return attrs?.map((attr, i) => {
            return (
                <Column
                    key={`col_${i}`}
                    allowFiltering={true}
                    caption={attr?.name}
                    dataField={`joined[${attr.collection}][${attr.name}]`}
                    dataType={'string'}
                    width={attr?.width}
                    visible={true}
                    allowSorting={true}
                    filterOperations={['contains']}
                    encodeHtml={true}
                    cellRender={cellUrlRender}
                    defaultSortOrder={attr.sortOrder}
                />
            );
        });
    }, []);

    const getWidth = useCallback(() => {
        let w = 100;
        p.gridAttribute?.attrs.forEach((x) => {
            w = w + x.width;
        });
        return w;
    }, []);

    const cellUrlRender = (param: any) => {
        if (p.isFormData && param.data.url && param.data.urlAttributes.indexOf(param.column.caption) !== -1) {
            return (
                <a target="_blank" href={param.data.url}>
                    {param.value}
                </a>
            );
        } else {
            return <>{param.value}</>;
        }
    };

    const onRowClick = useCallback((e: any) => {
        if (checkIsRowSelectable(e.data)) {
            if (e.component.getSelectedRowKeys().indexOf(e.key) > -1) {
                e.component.deselectRows([e.key]);
            } else {
                e.component.selectRows([e.key], true);
            }
        }
    }, []);

    // сортируем столбцы в порядке как они заданы в шаблоне
    const gridAttributesKeys = p.gridAttribute?.attrs.map((val) => val.key) ?? [];
    const sortedDictAttributes = [...p.dictAttributes]?.sort(
        (a, b) =>
            (gridAttributesKeys.indexOf(a) === -1 ? Infinity : gridAttributesKeys.indexOf(a)) -
            (gridAttributesKeys.indexOf(b) === -1 ? Infinity : gridAttributesKeys.indexOf(b)),
    );

    if (sortedDictAttributes && p.maxStructLevel !== undefined) {
        return (
            <DevExpressTreeList
                key={'tree'}
                width={getWidth}
                dataSource={treeSource}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                showColumnHeaders={true}
                columnAutoWidth={true}
                columnMinWidth={30}
                allowColumnReordering={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                noDataText={'Нет строк'}
                ref={treeRef}
                onEditorPreparing={onTreeEditorPreparing}
                onInitialized={onTreeInitialized}
                onSelectionChanged={onSelectionChanged}
                onNodesInitialized={onTreeNodesInitialized}
                onContentReady={onTreeContentReady}
                // defaultSelectedRowKeys={selectedDefTreeKeys}
                parentIdExpr="parent"
                hasItemsExpr="hasChild"
                keyExpr="id"
                rootValue=""
                onRowClick={onRowClick}
                onCellHoverChanged={onCellHoverChanged}
            >
                <Selection mode="multiple" allowSelectAll={false} />
                {getCodeColumn()}
                {getSchemeColumns(sortedDictAttributes)}
                {getColumns()}
                <ColumnFixing enabled={true} />
                <FilterRow
                    showOperationChooser={true}
                    visible={true}
                    applyFilter={applyFilterOnClick ? 'onClick' : 'auto'}
                />
                <RemoteOperations filtering={true} sorting={false} />
                <Scrolling mode="virtual" />
                <Paging enabled={true} defaultPageSize={10} />
            </DevExpressTreeList>
        );
    }

    return <></>;
};

export default TreeModeControl;
