import './WinnerSelectionTablePositions.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICustomComponentProps } from '@molecules/formbuilder/controls/CustomComponent/CustomComponent';
import { DataGrid } from 'devextreme-react/data-grid';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import WinnerSelectionTableDemand from '../WinnerSelectionTableDemand/WinnerSelectionTableDemand';
import { WinnerSelectionService } from '@services/WinnerSelectionService';
import { IPosition, ITablePositionsSettings } from '@models/winnerSelection/ITablePositions';
import { formatPrice } from '@utils/helpers';
import PageWithSections from '@pages/PageWithSections';
import { IPageSection } from '@/types/projects.types';
import Button from '@atoms/Button';
import { MdClose, MdFullscreen } from 'react-icons/md';
import { createPortal } from 'react-dom';

export interface IWinnerSelectionTablePositionsProps<TFieldValues extends object = object>
    extends ICustomComponentProps<TFieldValues> {}

const WinnerSelectionTablePositions = <TFieldValues extends object = object>({
    component,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: IWinnerSelectionTablePositionsProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const gridRef = useRef<DataGrid>(null);

    const [positions, setPositions] = useState<IPosition[]>();
    const [settings, setSettings] = useState<ITablePositionsSettings>();
    const [errorText, setErrorText] = useState<string>();

    const [fullScreenPosition, setFullScreenPosition] = useState<IPosition>();

    const tableKey = component.params?.tableKey;

    const loadData = useCallback(() => {
        gridRef.current?.instance.beginCustomLoading('Загрузка');

        if (!tableKey) {
            console.error('WinnerSelectionTablePositions. Ошибка загрузки данных (tableKey пустой)');
            return;
        }

        WinnerSelectionService.fetchTablePositionsData(docId, tableKey)
            .then((response) => {
                setPositions(response.data.positions);
                setSettings(response.data.settings);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                gridRef.current?.instance.endCustomLoading();
            });
    }, [docId, tableKey]);

    useEffect(() => {
        activated.current = true;

        loadData();

        return () => {
            activated.current = false;
        };
    }, []);

    const getPositionHeaderText = useCallback((position: IPosition) => {
        return `${position.name || ''}. 
        Количество: ${position.quantity !== null && position.quantity !== undefined ? position.quantity : ''}. 
        Единица измерения: ${position.units || ''}. 
        Стоимость: ${formatPrice(position.price, position.currencyCode)} (${position.currencyText || ''})`;
    }, []);

    const posSections: IPageSection[] = useMemo(() => {
        return (
            positions?.map((position, index) => {
                return {
                    title: (
                        <div className="winnerSelectionTablePositions__position-header">
                            <div className="winnerSelectionTablePositions__position-header-text">
                                {getPositionHeaderText(position)}
                            </div>
                            <Button
                                className="winnerSelectionTablePositions__fullscreen-btn"
                                buttonType="secondary"
                                size="s"
                                startAdornment={<MdFullscreen size="16" />}
                                onClick={() => {
                                    setFullScreenPosition(position);
                                }}
                            >
                                На весь экран
                            </Button>
                        </div>
                    ),
                    asideTitle: `${position.name || ''}`,
                    id: position.key,
                    component: (
                        <WinnerSelectionTableDemand
                            isEdit={isEdit}
                            isNew={isNew}
                            fields={fields}
                            component={component}
                            formMethods={formMethods}
                            docId={position.key}
                        />
                    ),
                } as IPageSection;
            }) ?? []
        );
    }, [positions]);

    return (
        <div className="winnerSelectionTablePositions-wrapper">
            {errorText ? (
                <Hint icon="info" title={`Ошибка загрузки: ${errorText}`} variant="red" maxWidth="100%" />
            ) : null}
            {positions !== undefined ? (
                positions.length === 0 ? (
                    <div className="winnerSelectionTablePositions__empty">
                        <h1>{settings?.emptyTextHeader}</h1>
                        <p>{settings?.emptyText}</p>
                    </div>
                ) : (
                    <PageWithSections
                        showNavigationPosition={true}
                        showHeader={false}
                        sections={posSections}
                        parentSelector={'.doc-tab'}
                        asideHeader="Позиции"
                    />
                )
            ) : (
                <div className={'winnerSelectionTablePositions__loading'}>
                    <Preloader position="inline" size="l" />
                </div>
            )}
            {fullScreenPosition
                ? createPortal(
                      <div className={'winnerSelectionTablePositions__fullScreenItem'}>
                          <div className="winnerSelectionTablePositions__position-header">
                              <div className="winnerSelectionTablePositions__position-header-text">
                                  {getPositionHeaderText(fullScreenPosition)}
                              </div>
                              <Button
                                  className="winnerSelectionTablePositions__exit-fullscreen-btn"
                                  buttonType="secondary"
                                  size="s"
                                  startAdornment={<MdClose size="16" />}
                                  onClick={() => {
                                      setFullScreenPosition(undefined);
                                  }}
                              >
                                  Закрыть
                              </Button>
                          </div>
                          <WinnerSelectionTableDemand
                              isEdit={isEdit}
                              isNew={isNew}
                              fields={fields}
                              component={component}
                              formMethods={formMethods}
                              docId={fullScreenPosition.key}
                          />
                      </div>,
                      document.body,
                  )
                : null}
        </div>
    );
};

export default WinnerSelectionTablePositions;
