import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDocumentData } from '@models/document/IDocumentData';
import { IDocumentScheme } from '@models/document/IDocumentScheme';
import { IDocumentStatusBar } from '@models/document/IDocumentStatusBar';
import { IDocumentActions, IDocumentTitleBarActions } from '@models/actions/IDocumentActions';
import { IBaseAction } from '@models/actions/IBaseAction';
import { IAllRoutesActions } from '@models/actions/IAllRoutesActions';
import { IDocumentRouteMap, IDocumentRouteMapAction } from '@models/document/IDocumentRouteMap';
import { IWarningAction } from '@/models/document/IDocumentResult';

export interface IDocumentModalState {
    data: IDocumentData;
    scheme: IDocumentScheme;
}

export interface IDocumentState {
    isEdit: boolean;
    isNew: boolean;
    isHistoryView: boolean;
    isHistoryDiffMode: boolean;
    isLoading: boolean;
    isLoadingStatusBar: boolean;
    isLoadingTitleBarActions: boolean;
    isLoadingMenuDocumentActions: boolean;
    isLoadingMenuAllRoutesActions: boolean;
    isFavorite: boolean;
    idDocHashCheck: boolean;
    scheme?: IDocumentScheme;
    data?: IDocumentData;
    statusBar?: IDocumentStatusBar;
    actions: IDocumentActions;
    routeMap: IDocumentRouteMap;
    warningAction?: IWarningAction;
    partsVersionToAttach?: string[];
}

const initialState: IDocumentState = {
    isEdit: false,
    isNew: false,
    isHistoryView: false,
    isHistoryDiffMode: false,
    isLoading: true,
    isLoadingStatusBar: true,
    isLoadingTitleBarActions: true,
    isLoadingMenuDocumentActions: false,
    isLoadingMenuAllRoutesActions: false,
    isFavorite: false,
    idDocHashCheck: false,
    scheme: undefined,
    data: undefined,
    statusBar: undefined,
    actions: {
        titleBarActions: undefined,
        menuActions: {
            documentActions: undefined,
            allRoutesActions: undefined,
        },
    },
    routeMap: {
        addAction: { isVisibleAction: false, actionConfig: undefined },
    },
};

export const DocumentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoadingStatusBar: (state, action: PayloadAction<boolean>) => {
            state.isLoadingStatusBar = action.payload;
        },
        setIsLoadingTitleBarActions: (state, action: PayloadAction<boolean>) => {
            state.isLoadingTitleBarActions = action.payload;
        },
        setIsLoadingMenuDocumentActions: (state, action: PayloadAction<boolean>) => {
            state.isLoadingMenuDocumentActions = action.payload;
        },
        setIsLoadingMenuAllRoutesActions: (state, action: PayloadAction<boolean>) => {
            state.isLoadingMenuAllRoutesActions = action.payload;
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload;
        },
        setIsNew: (state, action: PayloadAction<boolean>) => {
            state.isNew = action.payload;
        },
        setIsHistoryView: (state, action: PayloadAction<boolean>) => {
            state.isHistoryView = action.payload;
        },
        setIsHistoryDiffMode: (state, action: PayloadAction<boolean>) => {
            state.isHistoryDiffMode = action.payload;
        },
        setIsFavorite: (state, action: PayloadAction<boolean>) => {
            state.isFavorite = action.payload;
        },
        setIdDocHashCheck: (state, action: PayloadAction<boolean>) => {
            state.idDocHashCheck = action.payload;
        },
        setRouteMapAddActionConfig: (state, action: PayloadAction<IDocumentRouteMapAction>) => {
            state.routeMap.addAction = action.payload;
        },
        successLoadScheme: (state, action: PayloadAction<IDocumentScheme>) => {
            state.scheme = action.payload;
        },
        successLoadData: (state, action: PayloadAction<IDocumentData>) => {
            state.data = action.payload;
        },
        reset: (state) => {
            state.isEdit = false;
            state.isNew = false;
            state.scheme = undefined;
            state.data = undefined;
            state.isHistoryView = false;
            state.actions.titleBarActions = undefined;
            state.actions.menuActions.documentActions = undefined;
            state.actions.menuActions.allRoutesActions = undefined;
            state.statusBar = undefined;
        },
        resetActions: (state) => {
            state.actions.titleBarActions = undefined;
            state.actions.menuActions.documentActions = undefined;
            state.actions.menuActions.allRoutesActions = undefined;
        },
        successLoadStatusBar: (state, action: PayloadAction<IDocumentStatusBar>) => {
            state.statusBar = action.payload;
        },
        successLoadTitleBarActions: (state, action: PayloadAction<IDocumentTitleBarActions>) => {
            state.actions.titleBarActions = action.payload;
        },
        successLoadMenuDocumentActions: (state, action: PayloadAction<IBaseAction[]>) => {
            state.actions.menuActions.documentActions = action.payload;
        },
        successLoadMenuAllRoutesActions: (state, action: PayloadAction<IAllRoutesActions>) => {
            state.actions.menuActions.allRoutesActions = action.payload;
        },
        setActionWarning: (state, action: PayloadAction<IWarningAction | undefined>) => {
            state.warningAction = action.payload;
        },
        setPartsVersionToAttach: (state, action: PayloadAction<string[]>) => {
            state.partsVersionToAttach = action.payload;
        },
    },
});

export const { setIsLoading, setIsEdit, successLoadScheme, successLoadData } = DocumentSlice.actions;

export default DocumentSlice.reducer;
