import './SupplyScheduleTable.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICustomComponentProps } from '@molecules/formbuilder/controls/CustomComponent/CustomComponent';
import { Column, DataGrid, Export, FilterRow, Item, LoadPanel, Scrolling, Toolbar } from 'devextreme-react/data-grid';
import Hint from '@atoms/Hint';
import {
    ISupplyScheduleItem,
    ISupplyScheduleSettings,
    SupplyMonth,
    SupplyYear,
} from '@models/supplySchedule/ISupplyScheduleTable';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { formatPrice } from '@utils/helpers';
import Preloader from '@atoms/Preloader';
import { SupplyScheduleService } from '@services/SupplyScheduleService';
import { classnames } from '@utils/classnames';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import Tooltip from '@atoms/Tooltip';
import Switch from '@atoms/Switch';
import Segment from '@atoms/Segment';
import { ISegment } from '@/types';
import { exportGridToExcel } from '@utils/devextreme-react/dataGridUtils';
import { months } from '@utils/helpersDatePicker';
import Menu from '@atoms/Menu';
import Button from '@atoms/Button';
import { MdSettings } from 'react-icons/md';
import { setDataValueAttr } from '@utils/dataGridUtils';

export interface ISupplyScheduleTableProps<TFieldValues extends object = object>
    extends ICustomComponentProps<TFieldValues> {}

export enum YearsShowType {
    AllYears,
    CurrentYear,
}

const SupplyScheduleTable = <TFieldValues extends object = object>({
    component,
    formMethods,
    isEdit,
    isNew,
    fields,
    docId,
    ...props
}: ISupplyScheduleTableProps<TFieldValues>) => {
    const activated = React.useRef(false);
    const gridRef = useRef<DataGrid>(null);

    const [settings, setSettings] = useState<ISupplyScheduleSettings>();
    const [errorText, setErrorText] = useState<string>();
    const [items, setItems] = useState<ISupplyScheduleItem[]>([]);
    const [yearsMonths, setYearsMonths] = useState<Record<SupplyYear, Set<SupplyMonth>>>({});
    const keys = useMemo(() => Object.keys(settings?.keys ?? []), [settings]);

    const tableKey = component.params?.tableKey;

    const gridStore = useMemo(() => {
        return new DataSource({
            store: new ArrayStore({
                key: keys.map((key) => `keyValues.${key}`),
                data: items,
            }),
        });
    }, [items, settings]);

    useEffect(() => {
        activated.current = true;

        loadData();

        return () => {
            activated.current = false;
        };
    }, []);

    const getUniqueYearsAndMonths = (
        data: ISupplyScheduleItem[] | undefined,
        hideEmptyMonths: boolean,
        currentYear: boolean,
    ) => {
        const yearsMonths: Record<SupplyYear, Set<SupplyMonth>> = {};

        const allMonths: SupplyMonth[] = Array.from({ length: 12 }, (_, i) => (i + 1).toString());

        const currentYearValue = new Date().getFullYear().toString();

        if (data) {
            data.forEach((item) => {
                Object.keys(item.data).forEach((year) => {
                    // Если currentYear = true, обрабатываем только текущий год
                    if (currentYear && year !== currentYearValue) {
                        return; // Пропускаем все годы, кроме текущего
                    }

                    if (!yearsMonths[year]) {
                        yearsMonths[year] = new Set();
                    }

                    if (hideEmptyMonths) {
                        // Add only the months available in the data
                        Object.keys(item.data[year]).forEach((month) => {
                            yearsMonths[year].add(month);
                        });
                    } else {
                        // Add all months regardless of data availability
                        allMonths.forEach((month) => {
                            yearsMonths[year].add(month);
                        });
                    }
                });
            });
        }

        return yearsMonths;
    };

    const loadData = useCallback(() => {
        gridRef.current?.instance.beginCustomLoading('Загрузка');
        gridRef.current?.instance.beginUpdate();

        if (!tableKey) {
            console.error('SupplyScheduleTable. Ошибка загрузки данных (tableKey пустой)');
            return;
        }

        SupplyScheduleService.fetchTableData(docId, tableKey)
            .then((response) => {
                const tableData = response.data;
                setItems(tableData.data);
                setSettings(tableData.settings);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                gridRef.current?.instance.endCustomLoading();
                gridRef.current?.instance.endUpdate();
            });
    }, [docId, tableKey]);

    const formatKeyValue = (template: string, data: Record<string, string>): string => {
        return template.replace(/{(.*?)}/g, (_, key) => data[key] || '');
    };

    const monthCellRender = useCallback(
        (p: any) => {
            const { data, column } = p;
            const year = column.dataField.split('.')[1];
            const month = column.dataField.split('.')[2];

            if (data?.data?.[year]?.[month]?.positions) {
                const positions = data.data[year][month].positions;

                // Render positions list as tooltip content
                const tooltipContent = (
                    <ul className="tooltip-position-list">
                        {positions.map((position: any) => (
                            <li key={position.doc_id} className="tooltip-position-item">
                                {settings?.isAdminView ? (
                                    <a href={'/document/' + position.doc_id} className="position-name">
                                        {position.position_name}
                                    </a>
                                ) : (
                                    <div className="position-name">{position.position_name}</div>
                                )}
                                <div className="position-cost">
                                    Цена: {formatPrice(position.cost_value, 'RUB') || 'Не указана'}
                                </div>
                                <div className="position-date">
                                    Дата поставки:{' '}
                                    {new Date(position.supply_date).toLocaleDateString('ru-RU') || 'Не указана'}
                                </div>
                            </li>
                        ))}
                    </ul>
                );

                return (
                    <Tooltip openDelay={100} offset={[0, 0]} background="transparent" hidePadding={true} position="top">
                        <span>{p.text || ''}</span>
                        {tooltipContent}
                    </Tooltip>
                );
            }
            return <span>{p.text || ''}</span>;
        },
        [settings?.isAdminView],
    );

    const yearCellRender = useCallback(
        (p: any) => {
            const { data, column } = p;
            const year = column.dataField.split('.')[1];

            // Get the data for the specific year
            const yearData = (data as ISupplyScheduleItem).data[year];

            if (yearData) {
                // Gather all positions for the current year and all months
                const allPositions = Object.values(yearData || {}).flatMap((monthData) => monthData?.positions || []);

                // Create tooltip content with the list of positions
                const tooltipContent =
                    allPositions.length > 0 ? (
                        <ul className="tooltip-position-list">
                            {allPositions.map((position: any) => (
                                <li key={position.doc_id} className="tooltip-position-item">
                                    {settings?.isAdminView ? (
                                        <a href={'/document/' + position.doc_id} className="position-name">
                                            {position.position_name}
                                        </a>
                                    ) : (
                                        <div className="position-name">{position.position_name}</div>
                                    )}
                                    <div className="position-cost">
                                        Цена: {formatPrice(position.cost_value, 'RUB') || 'Не указана'}
                                    </div>
                                    <div className="position-date">
                                        Дата поставки:{' '}
                                        {new Date(position.supply_date).toLocaleDateString('ru-RU') || 'Не указана'}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div>Нет позиций для отображения</div>
                    );

                return (
                    <Tooltip openDelay={100} offset={[0, 0]} background="transparent" hidePadding={true} position="top">
                        <span>{p.text || ''}</span>
                        {tooltipContent}
                    </Tooltip>
                );
            }
            return <span>{p.text || ''}</span>;
        },
        [settings?.isAdminView],
    );

    // -------------------------------------------------------------------------------------------------------------------
    // Настройки
    // -------------------------------------------------------------------------------------------------------------------
    const [hideEmptyMonths, setHideEmptyMonths] = useState<boolean>(false);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [yearsShowSetting, setYearsShowSetting] = useState<YearsShowType>(YearsShowType.AllYears);

    useEffect(() => {
        setYearsMonths(getUniqueYearsAndMonths(items, hideEmptyMonths, yearsShowSetting === YearsShowType.CurrentYear));
    }, [hideEmptyMonths, items, yearsShowSetting]);

    useEffect(() => {
        if (!showFilterRow) {
            gridRef.current?.instance.clearFilter();
        }
    }, [showFilterRow]);

    const yearsShowSettingsList = useMemo<ISegment[]>(() => {
        return [
            {
                value: '0',
                label: 'Все года',
            },
            {
                value: '1',
                label: 'Текущий год',
            },
        ];
    }, []);

    const getSettingsContent = () => {
        return (
            <div className="supply-schedule-preferences__content">
                <div className="supply-schedule-preferences__item">
                    <Switch
                        label="Пустые месяцы"
                        helperText={hideEmptyMonths ? 'скрывать' : 'показывать'}
                        value={!hideEmptyMonths}
                        between={true}
                        reverse={true}
                        data={'data'}
                        onChange={() => setHideEmptyMonths(!hideEmptyMonths)}
                    />
                </div>
                <div className="supply-schedule-preferences__item">
                    <Switch
                        label="Фильтр"
                        helperText={showFilterRow ? 'включен' : 'выключен'}
                        value={showFilterRow}
                        between={true}
                        reverse={true}
                        data={'data'}
                        onChange={() => setShowFilterRow(!showFilterRow)}
                    />
                </div>
                <div className="supply-schedule-preferences__item">
                    <span className="supply-schedule-preferences__item-label">Отображаемые периоды</span>
                    <Segment
                        list={yearsShowSettingsList}
                        fullWidth={true}
                        value={yearsShowSettingsList[yearsShowSetting]}
                        onChange={(e) => {
                            setYearsShowSetting(+e.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    // -------------------------------------------------------------------------------------------------------------------

    const onExporting = () => {
        exportGridToExcel(gridRef, 'Экспорт таблицы');
    };

    const onCellPrepared = useCallback(async (cell: CellPreparedEvent<any, string>) => {
        if (cell.rowType === 'data') {
            setDataValueAttr(cell);
        }
    }, []);

    // -------------------------------------------------------------------------------------------------------------------

    const keysColumns = useMemo(() => {
        return keys.map((key, index, array) => (
            <Column
                key={key}
                dataField={`keyValues.${key}`}
                cssClass={classnames('supplyScheduleTable__key', {
                    'supplyScheduleTable__key--first': index === 0,
                    'supplyScheduleTable__key--last': index === array.length - 1,
                })}
                caption={settings?.keys[key].caption}
                visible={settings?.keys[key].visible}
                calculateDisplayValue={(rowData: ISupplyScheduleItem) => {
                    return settings?.keys[key].displayFormat
                        ? formatKeyValue(settings?.keys[key].displayFormat, rowData.keyValues)
                        : undefined;
                }}
                fixed={true}
                fixedPosition="left"
                alignment={'center'}
                width={settings?.keys[key].width ?? undefined}
                minWidth={100}
            />
        ));
    }, [keys, settings?.keys]);

    const monthsColumns = useMemo(() => {
        return Object.keys(yearsMonths).map((year) => (
            <Column key={year} cssClass="supplyScheduleTable__year" caption={year} alignment="center">
                {Array.from(yearsMonths[year]).map((month, index, array) => (
                    <Column
                        key={`${year}-${month}`}
                        dataField={`data.${year}.${month}.sumValue`}
                        cssClass={classnames('supplyScheduleTable__month', {
                            'supplyScheduleTable__month--first': index === 0,
                            'supplyScheduleTable__month--last': index === array.length - 1,
                        })}
                        cellRender={monthCellRender}
                        caption={months.ru[+month - 1]}
                        dataType="number"
                        format={(value: number) => formatPrice(value, 'RUB')}
                        minWidth={100}
                    />
                ))}
                {yearsMonths[year].size > 1 ? (
                    <Column
                        key={`${year}-sum`}
                        cssClass={classnames('supplyScheduleTable__sum')}
                        dataField={`data.${year}.sum`}
                        cellRender={yearCellRender}
                        caption={'Итого'}
                        allowSorting={true}
                        dataType="number"
                        format={(value: number) => formatPrice(value, 'RUB')}
                        calculateCellValue={(rowData: ISupplyScheduleItem) => {
                            if (!rowData.data[year]) return null;
                            return Object.values(rowData.data[year]).reduce(
                                (acc, val) => acc + (val?.sumValue || 0),
                                0,
                            );
                        }}
                        minWidth={100}
                    />
                ) : null}
            </Column>
        ));
    }, [monthCellRender, yearCellRender, yearsMonths]);

    return (
        <div className="supplyScheduleTable">
            {errorText ? (
                <Hint icon="info" title={`Ошибка загрузки: ${errorText}`} variant="red" maxWidth="100%" />
            ) : null}
            {settings !== undefined ? (
                items ? (
                    <DevExpressDataGrid
                        id="supplyScheduleTableGrid"
                        ref={gridRef}
                        dataSource={gridStore}
                        remoteOperations={true}
                        allowColumnResizing={true}
                        wordWrapEnabled={true}
                        hoverStateEnabled={true}
                        showBorders={false}
                        columnResizingMode="widget"
                        onExporting={onExporting}
                        onCellPrepared={onCellPrepared}
                    >
                        <Scrolling useNative={false} scrollByContent={true} />
                        <FilterRow visible={showFilterRow} />
                        <LoadPanel enabled />
                        <Export enabled={true} formats={['xlsx']} />

                        {keysColumns}
                        {monthsColumns}

                        <Toolbar>
                            <Item location="before" visible={true}>
                                <Menu
                                    content={getSettingsContent()}
                                    className={'supply-schedule-preferences'}
                                    position="bottom-end"
                                >
                                    <div className="supply-schedule-preferences__menu">
                                        <Button
                                            buttonType="round"
                                            title="Настройки"
                                            textColor="neutral"
                                            size="s"
                                            startAdornment={<MdSettings size="24" />}
                                        >
                                            Настройки
                                        </Button>
                                    </div>
                                </Menu>
                            </Item>
                            <Item name="exportButton" location="after" visible={true} />
                        </Toolbar>
                    </DevExpressDataGrid>
                ) : (
                    <div className="supplyScheduleTable__empty">
                        <h1>{settings?.emptyTextHeader}</h1>
                        <p>{settings?.emptyText}</p>
                    </div>
                )
            ) : (
                <div className={'supplyScheduleTable__loading'}>
                    <Preloader position="inline" size="l" />
                </div>
            )}
        </div>
    );
};

export default SupplyScheduleTable;
