import './DictpickerModal.scss';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { classnames } from '@utils/classnames';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { DictionariesService } from '@services/DictionariesService';
import { IDictionaryData } from '@models/dictionary/IDictionaryData';
import { IAddDictForm, IDictFilter, IExternalSearchDict } from '@models/Forms/IForms';

import { IDictSettings } from '../IDictSettings';

import ListModeControl from './ModeControls/ListModeControl';
import TreeModeControl from './ModeControls/TreeModeControl';
import DictExternalSearchModal from './DictExternalSearchModal';
import { IDictpickerRefActions } from '../Dictpicker';
import DictAddFormModal from './DictAddFormModal';
import { IFormData } from '@/models/IFormData';

export interface ISelectFromDictDialogProp extends IDictSettings {
    onCloseModal: () => void;
    onSubmitModal: (data: IDictionaryData[]) => void;
    onValidate?: (data: IDictionaryData[]) => Promise<boolean>;
    externalSearch?: IExternalSearchDict;
    addForm?: IAddDictForm;
}

const DictpickerModal: FC<ISelectFromDictDialogProp> = (p: ISelectFromDictDialogProp) => {
    const [dictAttributes, setDictAttributes] = useState<string[]>();
    const [selectedKeys, setSelectedKeys] = useState<string[]>(p.selected);
    const [maxStructLevel, setMaxStructLevel] = useState<number>(0);
    const [filterResponse, setFilterResponse] = useState<IDictFilter>();
    const [selectedRowItems, setSelectedRowItems] = useState<IDictionaryData[]>([]);
    const [showExternalSearch, setShowExternalSearch] = useState<boolean>(false);
    const [showAddForm, setShowAddForm] = useState<boolean>(false);
    const controlRef = useRef<IDictpickerRefActions>(null);
    const onSelectedRowItems = useCallback((value: IDictionaryData[]) => {
        setSelectedRowItems(value);
    }, []);
    const onSelectedKeys = useCallback((value: string[]) => {
        setSelectedKeys(value);
    }, []);

    const getFilterAsync = async () => {
        setFilterResponse(await p.getFiltersAsync());
    };

    useEffect(() => {
        if (!dictAttributes) {
            getFilterAsync().then(() => {
                if (p.isFormData) {
                    DictionariesService.fetchFormdataDictionaryGridScheme(p.dictName).then((response) => {
                        setDictAttributes(response.data.attributes);
                    });
                } else {
                    DictionariesService.fetchDictionaryGridScheme(p.dictName).then((response) => {
                        let attrs = [...response.data.attributes];
                        if (p.joinedDictionaries) {
                            p.joinedDictionaries.forEach((x) => {
                                x.outColumns.forEach((col) => attrs.push(col.name));
                            });
                        }
                        setDictAttributes(attrs);
                        setMaxStructLevel(response.data.maxStructLevel);
                    });
                }
            });
        }
    }, [p.dictName]);

    const toggleShowExternalSearch = () => {
        setShowExternalSearch(!showExternalSearch);
    };

    const toggleShowAddForm = () => {
        setShowAddForm(!showAddForm);
    };

    const ModalFooter = ({
        onClose,
        onSubmit,
    }: {
        onClose: () => void;
        onSubmit: (data: IDictionaryData[]) => void;
    }) => (
        <div className={'dictModal_footer'}>
            {p.externalSearch && (
                <div>
                    <Button onClick={toggleShowExternalSearch} buttonType="light" size="s">
                        {p.externalSearch.buttonName}
                    </Button>
                </div>
            )}

            {p.addForm && (
                <div>
                    <Button onClick={toggleShowAddForm} buttonType="light" size="s">
                        {p.addForm.buttonName}
                    </Button>
                </div>
            )}
            <div className={'left'}>
                <Button
                    onClick={async () => {
                        let isValid = true;
                        if (p.onValidate) {
                            isValid = await p.onValidate(selectedRowItems);
                        }
                        if (selectedKeys && selectedKeys.length === selectedRowItems.length && isValid) {
                            onSubmit(selectedRowItems);
                        }
                        if (isValid) {
                            onClose();
                        }
                    }}
                    size="s"
                    //  disabled={selectedKeys && selectedKeys.length == 0}
                >
                    Выбрать
                </Button>
                <Button onClick={onClose} buttonType="light" size="s">
                    Отменить
                </Button>
            </div>
        </div>
    );

    const CheckHierarchical = useCallback(() => {
        return maxStructLevel > 0;
    }, [maxStructLevel]);

    return dictAttributes && filterResponse && maxStructLevel !== undefined ? (
        <Modal
            className={classnames('dictModal', 'dx-dropdowneditor-overlay', {
                'dictModal--single-select': !p.isMultiple,
            })}
            size="content"
            footer={<ModalFooter onClose={p.onCloseModal} onSubmit={p.onSubmitModal} />}
            header={
                <div className={classnames('box')}>
                    <div>
                        <div>{p.modalTitle ? p.modalTitle : p.dictName}</div>
                    </div>
                    <div className={classnames('left')}>
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="xs"
                            aria-label="Закрыть окно"
                            onClick={() => {
                                p.onCloseModal();
                            }}
                            startAdornment={<MdClose size="24" />}
                        />
                    </div>
                </div>
            }
        >
            {p.isFormData || !CheckHierarchical() ? (
                <ListModeControl
                    onSelectedRowItems={onSelectedRowItems}
                    onSelectedKeys={onSelectedKeys}
                    dictAttributes={dictAttributes}
                    filterResponse={filterResponse!}
                    controlRef={controlRef}
                    {...p}
                />
            ) : (
                <TreeModeControl
                    onSelectedRowItems={onSelectedRowItems}
                    onSelectedKeys={onSelectedKeys}
                    maxStructLevel={maxStructLevel}
                    dictAttributes={dictAttributes}
                    filterResponse={filterResponse!}
                    controlRef={controlRef}
                    {...p}
                />
            )}
            {showExternalSearch && (
                <DictExternalSearchModal
                    settings={p.externalSearch!}
                    onCloseModal={() => {
                        toggleShowExternalSearch();
                    }}
                    onSubmitModal={(data: string) => {
                        controlRef.current?.setSelected([data]);
                    }}
                />
            )}
            {showAddForm && (
                <DictAddFormModal
                    settings={p.addForm!}
                    onClose={() => {
                        toggleShowAddForm();
                    }}
                    onSubmit={(data: IFormData) => {
                        let code = '';
                        let codeField = data.fields.find((x) => x.name === 'code');
                        if (codeField && codeField.value) {
                            code = codeField.value.toString();
                        }

                        let dataDict: IDictionaryData = {
                            id: '',
                            code: code,
                            structure: '',
                            hasChild: false,
                            parent: '',
                            urlAttributes: [],
                            url: '',
                            fullId: '',
                            fields: data.fields,
                        };
                        p.onSubmitModal([dataDict]);

                        toggleShowAddForm();
                        p.onCloseModal();
                    }}
                />
            )}
        </Modal>
    ) : (
        <></>
    );
};

export default DictpickerModal;
