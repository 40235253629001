import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import Preloader from '@atoms/Preloader';
import DevExpressDataGrid from '@atoms/DevExpress/DataGrid/DevExpressDataGrid';
import { Column, DataGrid, Paging, Selection } from 'devextreme-react/data-grid';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Hint from '@atoms/Hint';
import { ICreateDocByTemplateDialogModel } from '@models/document/actionsExecution/ICreateDocByTemplateDialogModel';
import { ResultDocumentActionType } from '@models/document/actionsExecution/ICreateDocByTemplateModel';
import { FilesService } from '@services/FilesService';
import { ModalSize } from '@atoms/Modal/Modal';
import { onCellHoverChanged } from '@utils/dataGridUtils';

export class CreateDocByTemplateDialogExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICreateDocByTemplateDialogExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CreateDocByTemplateDialogExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICreateDocByTemplateDialogExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CreateDocByTemplateDialogExecutorModal: React.FC<ICreateDocByTemplateDialogExecutorProps> = (
    props: ICreateDocByTemplateDialogExecutorProps,
) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [templatesModel, setTemplatesModel] = useState<ICreateDocByTemplateDialogModel>();
    const [templatesList, setTemplatesList] = useState<any[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const service = new DocumentExecutionService(props.docId);
    const filesService = new FilesService();
    const gridRef = useRef<DataGrid>(null);

    useEffect(() => {
        setIsBusy(true);
        service
            .getCreateDocByTemplateDialogModel(props.actionKey)
            .then((res) => {
                setTemplatesModel(res.data);
                setTemplatesList(res.data.tableValues);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    const onSelectionChanged = useCallback((e: any) => {
        setSelectedKeys(e.selectedRowKeys);
    }, []);

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>{props.title}</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" position="static" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <DevExpressDataGrid
                        dataSource={templatesList}
                        remoteOperations={true}
                        hoverStateEnabled={true}
                        columnHidingEnabled={false}
                        showColumnHeaders={true}
                        columnAutoWidth={true}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="widget"
                        noDataText={'Шаблоны отсутствуют'}
                        rowAlternationEnabled={true}
                        onSelectionChanged={onSelectionChanged}
                        ref={gridRef}
                        onCellHoverChanged={onCellHoverChanged}
                    >
                        <Selection
                            mode="multiple"
                            showCheckBoxesMode={'always'}
                            allowSelectAll={true}
                            selectAllMode={'page'}
                        />
                        {templatesModel?.tableColumns.map((column, i) => {
                            return (
                                <Column
                                    key={`col_${i}`}
                                    caption={`${column.title}`}
                                    dataField={`${column.dataField}`}
                                    dataType={'string'}
                                    allowSorting={false}
                                    allowHeaderFiltering={false}
                                    allowReordering={false}
                                    width={`${column.width}`}
                                />
                            );
                        })}
                        <Paging enabled={true} defaultPageSize={templatesModel?.pageSize} />
                    </DevExpressDataGrid>

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                disabled={selectedKeys.length == 0}
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    setIsBusy(true);

                                    let selectedRows = gridRef?.current?.instance.getSelectedRowsData();
                                    if (templatesModel) {
                                        if (templatesModel.result === ResultDocumentActionType.Download) {
                                            if (selectedRows) {
                                                filesService.downloadFile(
                                                    `documentExecution/downloadDialogTemplateDoc/${props.docId}/${props.actionKey}/${selectedRows[0].templateKey}`,
                                                );
                                            }
                                            props.completeHandler && props.completeHandler(false);
                                        } else {
                                            service
                                                .createDocByTemplateDialog(
                                                    props.actionKey,
                                                    selectedRows?.map((row) => row.templateKey as string) as string[],
                                                )
                                                .then(() => {
                                                    setIsBusy(false);
                                                    props.completeHandler && props.completeHandler(true);
                                                })
                                                .catch((error) => setErrorText(error))
                                                .finally(() => {
                                                    setIsBusy(false);
                                                });
                                        }
                                    } else {
                                        setErrorText('Не удалось получить модель данных.');
                                        setIsBusy(false);
                                    }
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
