import React, { useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { IActionExecutor } from '@utils/actions/IActionExecutor';

import './../actions.scss';
import Preloader from '@atoms/Preloader';
import Hint from '@atoms/Hint';
import Textarea from '@atoms/Textarea';
import { ModalSize } from '@atoms/Modal/Modal';
import { ProcessExecutionService } from '@services/actions/ProcessExecutionService';

export class RemoveFromControlExecutor implements IActionExecutor {
    private _completeHandler?: (isSucceed: boolean) => void;
    private _objId: string = '';
    private _okButtonText: string = '';
    private _cancelButtonText: string = '';
    private _modalSize: ModalSize = 'm';

    run = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        raiseVisualElement?: () => void,
        modalSize?: ModalSize | null,
    ) => {
        this._completeHandler = completeHandler;
        this._objId = objId;
        this._okButtonText = action.options?.okButtonText ?? 'ОК';
        this._cancelButtonText = action.options?.cancelButtonText ?? 'Отмена';
        this._modalSize = modalSize ?? action.options?.modalSize ?? 'm';
    };

    visualElement = () => {
        return (
            <RemoveFromControlExecutorModal
                completeHandler={this._completeHandler}
                objId={this._objId}
                okButtonText={this._okButtonText}
                cancelButtonText={this._cancelButtonText}
                modalSize={this._modalSize}
            />
        );
    };
}

interface IActionDialogProps {
    objId: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const RemoveFromControlExecutorModal: React.FC<IActionDialogProps> = (props: IActionDialogProps) => {
    const [loading, setLoading] = useState<boolean>();
    const [errorText, setErrorText] = useState<string>();
    const [comment, setComment] = useState<string>();

    const onSubmit = () => {
        let service = new ProcessExecutionService();

        setLoading(true);
        service
            .removeFromControl(props.objId, comment ?? '')
            .then((x) => {
                if (x.status == 200 && props?.completeHandler) props.completeHandler(true);
            })
            .catch((reason) => {
                setErrorText(reason);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>
                            <span>Снять с контроля</span>
                        </div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props?.completeHandler) props?.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {loading ? (
                <Preloader size="m" position="static" />
            ) : (
                <div className="actForm">
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth="100%" />}

                    <Textarea
                        className="calendarInput"
                        placeholder="Комментарий"
                        rows={6}
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                    />

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                aria-label={props.okButtonText}
                                size="s"
                            >
                                {props.okButtonText}
                            </Button>
                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    props.completeHandler && props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
