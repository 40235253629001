import React, { FC, useRef } from 'react';
import './DocumentTabLoadEisLog.scss';

import DevExpressDataGridDynamic from '@atoms/DevExpress/DataGrid/DevExpressDataGridDynamic';
import Box from '@atoms/Box';
import { DataGridStateService } from '@services/DataGridStateService';
import { FilesService } from '@services/FilesService';
import { Template } from 'devextreme-react/core/template';
import { DocumentLoadEisLogService } from '@services/DocumentLoadEisLogService';
import Button from '@atoms/Button/Button';
import { MdDownload } from 'react-icons/md';
import { DropdownContainerContext } from '@/context/Contexts';

export interface IDocumentTabLoadEisLogProps {
    /** ИД документа */
    docId: string;
}

const DocumentTabLoadEisLog: FC<IDocumentTabLoadEisLogProps> = ({ docId }: IDocumentTabLoadEisLogProps) => {
    const serviceGrid = new DocumentLoadEisLogService();
    const stateService = new DataGridStateService();
    const filesService = new FilesService();
    const tabRef = useRef<HTMLDivElement>(null);

    const onRowClick = (e: any) => {};

    const getActivityActionsDownloadXML = () => {
        return (
            <Template
                key="dxGridRowActivityActionsDownloadXMLCellTemplate"
                name="dxGridRowActivityActionsDownloadXMLCellTemplate"
                render={function (data: any) {
                    const id = data.data.fields.ID;
                    const signed = (data.data.fields.HasSignedData as string).toLowerCase() === 'true';

                    return (
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="s"
                            aria-label="XML"
                            startAdornment={<MdDownload size="18" />}
                            onClick={() => {
                                if (signed) {
                                    filesService.downloadFile(`document/downloadEisLogSign/${id}/1`);
                                } else {
                                    filesService.downloadFile(`document/downloadEisLogXml/${id}`);
                                }
                            }}
                        >
                            XML
                        </Button>
                    );
                }}
            />
        );
    };

    const getActivityActionsDownloadSign = () => {
        return (
            <Template
                key="dxGridRowActivityActionsDownloadSignCellTemplate"
                name="dxGridRowActivityActionsDownloadSignCellTemplate"
                render={function (data: any) {
                    const id = data.data.fields.ID;
                    const sign = (data.data.fields.HasSign as string).toLowerCase() === 'true';

                    return sign ? (
                        <Button
                            buttonType="text"
                            textColor="neutral"
                            size="s"
                            aria-label="Подпись"
                            startAdornment={<MdDownload size="18" />}
                            onClick={() => {
                                filesService.downloadFile(`document/downloadEisLogSign/${id}/2`);
                            }}
                        >
                            Подпись
                        </Button>
                    ) : (
                        <></>
                    );
                }}
            />
        );
    };

    const templates: JSX.Element[] = [getActivityActionsDownloadXML(), getActivityActionsDownloadSign()];

    return (
        <DropdownContainerContext.Provider value={tabRef}>
            <div className="eis-log-tab" ref={tabRef}>
                <Box header="История отправки ЕИС">
                    <div className="eis-log-table">
                        <DevExpressDataGridDynamic
                            key="grid_load_eis_log"
                            stateService={stateService}
                            dataKey={docId}
                            gridKey="load_eis_log"
                            onRowClick={onRowClick}
                            service={serviceGrid}
                            filesService={filesService}
                            customTemplates={templates}
                        />
                    </div>
                </Box>
            </div>
        </DropdownContainerContext.Provider>
    );
};

export default DocumentTabLoadEisLog;
